var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "6", col: "12" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save()
                },
              },
            },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Roster")]),
                            _c("th", [_vm._v("Budget")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.draftOrder, function (roster) {
                            return _c("tr", { key: roster.id }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.getRosterName(roster.roster_id))
                                ),
                              ]),
                              _c(
                                "td",
                                { staticClass: "budget" },
                                [
                                  _c("app-number-field", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: roster.budget,
                                      callback: function ($$v) {
                                        _vm.$set(roster, "budget", $$v)
                                      },
                                      expression: "roster.budget",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("app-primary-button", [_vm._v("Save changes")]),
              _c("saved-indicator", { attrs: { saved: _vm.saved } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }