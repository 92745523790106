var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league
    ? _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" League Notes")]),
          _c("v-card-subtitle", [
            _vm._v(
              " League notes are used to share information between league members (eg: payouts or house rules). Only the commissioner can set league notes. "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c("app-text-area", {
                attrs: { readonly: "", value: _vm.league.notes },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }