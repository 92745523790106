var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.siteOffline
        ? _c("desktop-app-bar", {
            staticClass: "hidden-sm-and-down",
            attrs: {
              hasLeague: _vm.hasLeague,
              hasRoster: _vm.hasRoster,
              isAdmin: _vm.isAdmin,
              isCommissioner: _vm.isCommissioner,
              isAnonymous: _vm.isAnonymous,
              leagueId: _vm.leagueId,
              userId: _vm.userId,
              username: _vm.username,
              hasNotes: _vm.hasNotes,
              isMod: _vm.isMod,
            },
          })
        : _vm._e(),
      !_vm.siteOffline
        ? _c("mobile-app-bar", {
            staticClass: "hidden-md-and-up",
            attrs: {
              hasLeague: _vm.hasLeague,
              hasRoster: _vm.hasRoster,
              isAdmin: _vm.isAdmin,
              isCommissioner: _vm.isCommissioner,
              isAnonymous: _vm.isAnonymous,
              leagueId: _vm.leagueId,
              userId: _vm.userId,
              username: _vm.username,
              hasNotes: _vm.hasNotes,
              isMod: _vm.isMod,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }