var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.national_status == "N"
    ? _c(
        "v-icon",
        { staticClass: "mb-1", attrs: { "x-small": "", color: "red" } },
        [_vm._v("mdi-leaf-maple")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }