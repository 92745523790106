var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "spot mb-1" },
    [
      _c(
        "v-col",
        { staticClass: "d-none d-md-block", attrs: { cols: "0", md: "1" } },
        [
          _vm.playerToBeMoved && _vm.playerToBeMoved == _vm.spot.player
            ? _c(
                "span",
                [
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("confirmDrop", _vm.spot)
                        },
                      },
                    },
                    [_vm._v("mdi-minus")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.canReceive
            ? _c(
                "span",
                [
                  _c(
                    "v-icon",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("acceptPlayer", _vm.spot)
                        },
                      },
                    },
                    [_vm._v("mdi-arrow-right-bold")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("v-col", { attrs: { cols: "2", md: "1" } }, [
        !_vm.playerToBeMoved || _vm.playerToBeMoved != _vm.spot.player
          ? _c(
              "span",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-n2 mt-n2",
                    class: _vm.isLocked
                      ? "nohand"
                      : _vm.canEdit
                      ? "blue--text"
                      : "nohand",
                    attrs: {
                      icon: "",
                      text: "",
                      title: _vm.spot.name,
                      outlined: _vm.canEdit && !_vm.isLocked,
                    },
                    on: {
                      click: function ($event) {
                        _vm.canEdit ? _vm.$emit("movePlayer", _vm.spot) : null
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.name))]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.playerToBeMoved &&
        _vm.playerToBeMoved == _vm.spot.player &&
        !_vm.isLocked
          ? _c(
              "span",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "ml-n2 mt-n2",
                    attrs: {
                      icon: "",
                      text: "",
                      title: _vm.spot.name,
                      outlined: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cancelMovePlayer")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "7" } },
        [
          _vm.spot.player
            ? _c(
                "v-row",
                [
                  _c("player-link", {
                    attrs: {
                      player: _vm.spot.player,
                      leagueId: _vm.leagueId,
                      showTeamLogo: true,
                      showTeamName: false,
                      shortenName: false,
                      showPosition: true,
                      showPlayerStatus: true,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("v-row", [
            _vm.spot.player && _vm.scoreboard
              ? _c(
                  "span",
                  [
                    _c("game-state", {
                      attrs: {
                        player: _vm.spot.player,
                        scoreboard: _vm.scoreboard,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "text-right d-none d-md-block",
          attrs: { cols: "0", md: "3" },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("position-score", {
                    attrs: {
                      leagueId: _vm.leagueId,
                      position: _vm.spot,
                      scoreboard: _vm.scoreboard,
                      weekNumber: _vm.weekNumber,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.playerScore && _vm.enableProjections
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 caption grey--text" },
                    [_c("score", { attrs: { score: _vm.projection } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.playerToBeMoved
            ? _c("v-row", [
                _vm.playerToBeMoved == _vm.spot.player ? _c("span") : _vm._e(),
                _vm.canReceive ? _c("span") : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "text-right d-block d-md-none",
          attrs: { cols: "3", md: "0" },
        },
        [
          _c(
            "v-row",
            [
              !_vm.playerToBeMoved
                ? _c("v-col", { staticClass: "py-0" }, [
                    _vm.gameStarted
                      ? _c(
                          "span",
                          [
                            _c("position-score", {
                              attrs: {
                                leagueId: _vm.leagueId,
                                position: _vm.spot,
                                scoreboard: _vm.scoreboard,
                                weekNumber: _vm.weekNumber,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("--")]),
                  ])
                : _vm._e(),
              _vm.playerToBeMoved
                ? _c("v-col", [
                    _vm.playerToBeMoved &&
                    _vm.playerToBeMoved == _vm.spot.player
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-icon",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("confirmDrop", _vm.spot)
                                  },
                                },
                              },
                              [_vm._v("mdi-minus")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.canReceive
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-icon",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("acceptPlayer", _vm.spot)
                                  },
                                },
                              },
                              [_vm._v("mdi-arrow-left-bold")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          !_vm.playerToBeMoved && _vm.playerScore && _vm.enableProjections
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 caption grey--text" },
                    [_c("score", { attrs: { score: _vm.projection } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.playerToBeMoved
            ? _c("v-row", [
                _vm.playerToBeMoved == _vm.spot.player ? _c("span") : _vm._e(),
                _vm.canReceive ? _c("span") : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }