var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("player-list", {
    attrs: {
      leagueId: _vm.leagueId,
      showActions: _vm.showActions,
      inputFilters: _vm.filterPositions,
      toPositionId: _vm.toPositionId,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }