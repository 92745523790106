var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.draft && _vm.rosters
    ? _c("div", [
        _vm.draft && _vm.rosters
          ? _c(
              "div",
              { staticClass: "draft" },
              [
                _vm.isCommissioner
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("commissioner-draft-tools", {
                              attrs: {
                                draft: _vm.draft,
                                leagueId: _vm.leagueId,
                                previousSlot: _vm.previousSlot,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        !_vm.isCommissioner && _vm.draft.is_paused
                          ? _c("v-alert", { attrs: { type: "info" } }, [
                              _vm._v("The commissioner has paused the draft"),
                            ])
                          : _vm._e(),
                        _c("draft-events", {
                          attrs: { events: _vm.draft.draft_events },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-row"),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "9" } },
                      [
                        _c("player-list", {
                          attrs: {
                            leagueId: _vm.leagueId,
                            showActions:
                              _vm.isCommissioner && !_vm.draft.is_paused,
                            addFunction: _vm.addPlayer,
                            isDraft: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c("draft-rosters", {
                          attrs: {
                            isAuction: false,
                            leagueId: _vm.leagueId,
                            rosters: _vm.rosters,
                            isCommissionerDraft: true,
                            isCommissioner: _vm.isCommissioner,
                          },
                          on: { rosterChange: _vm.updateSelectedRosterId },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }