var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "draft-picks" },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "v-card-text",
        [
          _vm._l(_vm.slots, function (slot) {
            return _c("p", { key: slot.pick_number }, [
              _vm._v(" Pick " + _vm._s(slot.pick_number) + " - "),
              !slot.completed
                ? _c("span", [
                    _vm._v(_vm._s(_vm.getRosterName(slot.roster_id))),
                  ])
                : _vm._e(),
              slot.completed
                ? _c("span", [_vm._v(_vm._s(slot.result))])
                : _vm._e(),
            ])
          }),
          _vm.slots.length == 0 ? _c("p", [_vm._v("N/A")]) : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }