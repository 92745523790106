var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league && _vm.schedule
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "mt-4" },
            [
              _c("v-card-title", [_vm._v("Playoff settings")]),
              _c("v-card-text", [
                _c(
                  "p",
                  [
                    _c("app-form-label", [
                      _vm._v(
                        " Playoff teams: " + _vm._s(_vm.playoffType) + " "
                      ),
                      _vm.schedule.enable_loser_playoff
                        ? _c("span", [_vm._v("+ bottom 2 (loser playoff)")])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "p",
                  [
                    _c("app-form-label", [
                      _vm._v(
                        "First playoff week: " +
                          _vm._s(_vm.schedule.first_playoff_week)
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.generated
            ? _c(
                "v-card",
                { staticClass: "mt-4" },
                [
                  _c("v-card-title", [_vm._v("Schedule")]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      [
                                        _c("th", [_vm._v("Week")]),
                                        _vm._l(
                                          _vm.matchupsPerWeek,
                                          function (x) {
                                            return _c("th", { key: x }, [
                                              _vm._v("Matchup " + _vm._s(x)),
                                            ])
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.schedule.weeks, function (week) {
                                      return _c(
                                        "tr",
                                        { key: week.week_number },
                                        [
                                          _c("th", [
                                            _vm._v(_vm._s(week.week_number)),
                                          ]),
                                          _vm._l(
                                            week.matchups,
                                            function (matchup, index) {
                                              return _c("td", { key: index }, [
                                                matchup.type == "regular"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          matchup.away.name
                                                        ) +
                                                          " @ " +
                                                          _vm._s(
                                                            matchup.home.name
                                                          )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          matchup.type_display
                                                        )
                                                      ),
                                                    ]),
                                              ])
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          936995238
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("v-alert", { attrs: { type: "error" } }, [
                _vm._v(
                  "League schedule has not been set up by the commissioner yet."
                ),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }