var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    { attrs: { text: "", to: "/mod" } },
    [
      _vm._v("Mod Tools "),
      _vm.highlight
        ? _c("v-badge", {
            staticClass: "ml-2",
            attrs: { dot: "", color: "green" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }