var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h4" }, [
                    _vm._v("110 yards"),
                  ]),
                  _c("v-card-subtitle", { staticClass: "subtitle" }, [
                    _vm._v(" " + _vm._s(_vm.randomSlogan)),
                  ]),
                  _vm.hasLeagues
                    ? _c(
                        "v-card-text",
                        [
                          _c("h3", [
                            _vm._v("My Teams - Week " + _vm._s(_vm.weekNumber)),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            _vm._l(_vm.leagues, function (league) {
                              return _c(
                                "div",
                                { key: league.leagueId },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "title",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "league",
                                                  params: {
                                                    leagueId: league.id,
                                                  },
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(league.league_name))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("matchup-preview", {
                                    staticClass: "d-flex mb-10 matchup",
                                    attrs: {
                                      leagueId: league.id,
                                      weekNumber: _vm.weekNumber,
                                      matchup: league.matchup,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.hasLeagues
            ? _c(
                "v-col",
                { staticClass: "d-none d-md-flex", attrs: { cols: "4" } },
                [_c("scoreboard")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                [
                  !_vm.hasLeagues
                    ? _c("v-card-title", [
                        _vm._v("Join a league or create a new one!"),
                      ])
                    : _vm._e(),
                  _c("v-card-text", [
                    _c(
                      "p",
                      [
                        _c(
                          "app-primary-button",
                          { attrs: { to: { name: "join-league" } } },
                          [_vm._v("Join a league")]
                        ),
                        _vm._v(" or "),
                        _c(
                          "app-default-button",
                          { attrs: { to: { name: "create-league" } } },
                          [_vm._v(" Create a league ")]
                        ),
                      ],
                      1
                    ),
                    _vm.isAnonymous
                      ? _c(
                          "small",
                          [
                            _vm._v(" Already have a league? "),
                            _c("router-link", { attrs: { to: "/login" } }, [
                              _vm._v("Log in"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hasArchiveLeagues
        ? _c("archive-leagues", {
            attrs: { archiveLeagues: _vm.archiveLeagues },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }