var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card-text", [
    _c("p", { staticClass: "text-left" }, [
      _vm._v(
        " A sign in link has been sent to " +
          _vm._s(this.email) +
          ", please check your email and click the link to log in. "
      ),
    ]),
    _c("p", [_c("why-passwordless")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }