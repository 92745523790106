var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isAnonymous
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6", col: "12" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c("app-text-field", {
                    attrs: { label: "Email", readonly: "", disabled: "" },
                    model: {
                      value: _vm.userProfile.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.userProfile, "email", $$v)
                      },
                      expression: "userProfile.email",
                    },
                  }),
                  _c("app-text-field", {
                    attrs: {
                      label: "Display name",
                      rules: _vm.displayNameRules,
                      required: "",
                    },
                    model: {
                      value: _vm.form.displayName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "displayName", $$v)
                      },
                      expression: "form.displayName",
                    },
                  }),
                  _c("app-primary-button", [_vm._v("Update")]),
                  _c("saved-indicator", { attrs: { saved: _vm.saved } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }