var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAuction
        ? _c("auction-draft", {
            attrs: {
              leagueId: _vm.leagueId,
              draft: _vm.draft,
              rosters: _vm.rosters,
              isCommissioner: _vm.isCommissioner,
            },
          })
        : _vm._e(),
      _vm.isSnake
        ? _c("snake-draft", {
            attrs: {
              leagueId: _vm.leagueId,
              draft: _vm.draft,
              rosters: _vm.rosters,
              isCommissioner: _vm.isCommissioner,
            },
          })
        : _vm._e(),
      _vm.isCommissionerDraft
        ? _c("commissioner-draft", {
            attrs: {
              leagueId: _vm.leagueId,
              draft: _vm.draft,
              rosters: _vm.rosters,
              isCommissioner: _vm.isCommissioner,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }