var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.error
    ? _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                _vm._l(_vm.news, function (item) {
                  return _c(
                    "div",
                    { key: item.guid, staticClass: "news-item" },
                    [
                      _c("h4", [
                        _c(
                          "a",
                          { attrs: { href: item.link, target: "_blank" } },
                          [_vm._v(_vm._s(item.title))]
                        ),
                      ]),
                      _c("p", { staticClass: "text-body-2" }, [
                        _vm._v(_vm._s(item.description)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._m(0),
        ],
        1
      )
    : _c("div", [_vm._v("Failed to retrieve news")])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", { staticClass: "ma-2" }, [
      _vm._v("News powered by "),
      _c("a", { attrs: { href: "https://cflnewshub.com", target: "_blank" } }, [
        _vm._v("CFL News Hub"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }