var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.matchup
    ? _c(
        "v-container",
        [
          _vm.matchup.type == "loser"
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_vm._v(" Loser Game ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "5" } }, [
                _vm.matchup.away
                  ? _c(
                      "div",
                      { staticClass: "roster-name" },
                      [
                        _c(
                          "router-link",
                          {
                            class: _vm.matchupStateClass(
                              _vm.awayScore,
                              _vm.homeScore
                            ),
                            attrs: {
                              to: {
                                name: "roster",
                                params: {
                                  leagueId: _vm.leagueId,
                                  rosterId: _vm.matchup.away.id,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.matchup.away.name))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.matchup.away
                  ? _c("div", [_vm._v(_vm._s(_vm.noAwayTeamText))])
                  : _vm._e(),
              ]),
              _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
              _c("v-col", { staticClass: "text-right", attrs: { cols: "5" } }, [
                _vm.matchup.home
                  ? _c(
                      "div",
                      { staticClass: "roster-name" },
                      [
                        _c(
                          "router-link",
                          {
                            class: _vm.matchupStateClass(
                              _vm.homeScore,
                              _vm.awayScore
                            ),
                            attrs: {
                              to: {
                                name: "roster",
                                params: {
                                  leagueId: _vm.leagueId,
                                  rosterId: _vm.matchup.home.id,
                                },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.matchup.home.name))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.matchup.home ? _c("p", [_vm._v("TBD")]) : _vm._e(),
              ]),
              _c("v-col", { staticClass: "pt-0", attrs: { cols: "5" } }, [
                _vm.away && !_vm.isBye
                  ? _c(
                      "span",
                      {
                        class: _vm.matchupStateClass(
                          _vm.awayScore,
                          _vm.homeScore
                        ),
                      },
                      [
                        _c("roster-score", {
                          attrs: {
                            roster: _vm.away,
                            weekNumber: _vm.weekNumber,
                            calculatedScore: _vm.matchup.away_score,
                          },
                          on: { update: _vm.updateAwayScore },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                { staticClass: "pt-0 text-center", attrs: { cols: "2" } },
                [
                  _vm.matchupId && !_vm.isBye
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "matchup",
                              params: {
                                leagueId: _vm.leagueId,
                                weekNumber: _vm.weekNumber,
                                matchupId: _vm.matchupId,
                              },
                            },
                          },
                        },
                        [_vm._v("vs ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 text-right", attrs: { cols: "5" } },
                [
                  _vm.home
                    ? _c(
                        "span",
                        {
                          class: _vm.matchupStateClass(
                            _vm.homeScore,
                            _vm.awayScore
                          ),
                        },
                        [
                          _c("roster-score", {
                            attrs: {
                              roster: _vm.home,
                              weekNumber: _vm.weekNumber,
                              calculatedScore: _vm.matchup.home_score,
                            },
                            on: { update: _vm.updateHomeScore },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }