var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-simple-table", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Pos")]),
                _c("th", [_vm._v("Player")]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.draftablePositions, function (position) {
                return _c("tr", { key: position.id }, [
                  _c("td", [_vm._v(_vm._s(position.name))]),
                  position.player
                    ? _c(
                        "td",
                        [
                          _c("player-link", {
                            attrs: {
                              player: position.player,
                              showShortPlayerStatus: true,
                              showStatus: false,
                              showNational: true,
                              leagueId: _vm.leagueId,
                            },
                          }),
                        ],
                        1
                      )
                    : _c("td"),
                ])
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }