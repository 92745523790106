var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "400px" } },
    [
      _c("v-card-title", [_vm._v("Create a new account")]),
      !_vm.linkSent
        ? _c(
            "v-card-text",
            [
              _c(
                "p",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.register.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("app-text-field", {
                        attrs: {
                          label: "Display name",
                          rules: _vm.displayNameRules,
                          required: "",
                        },
                        model: {
                          value: _vm.displayName,
                          callback: function ($$v) {
                            _vm.displayName = $$v
                          },
                          expression: "displayName",
                        },
                      }),
                      _c("app-text-field", {
                        attrs: {
                          label: "Email",
                          rules: _vm.emailRules,
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _c("app-primary-button", { attrs: { block: "" } }, [
                        _vm._v("Register"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "text-center" }, [_vm._v("OR")]),
              _c("social", { staticClass: "text-center" }),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _c("router-link", { attrs: { to: "/forgotpassword" } }, [
                    _vm._v("Forgot your password?"),
                  ]),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _vm._v(" Already have an account? "),
                  _c("router-link", { attrs: { to: "/login" } }, [
                    _vm._v("Sign In"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.linkSent
        ? _c("passwordless-link-sent", { attrs: { email: _vm.email } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }