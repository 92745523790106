var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.waiversActive
        ? _c("v-alert", { attrs: { dense: "", type: "info" } }, [
            _vm._v(
              " Waivers are active, so you must bid on this player. Bids of $0 are allowed. "
            ),
          ])
        : _vm._e(),
      !_vm.canAddWithoutDrop
        ? _c("v-alert", { attrs: { dense: "", type: "warning" } }, [
            _vm._v(
              " You have no roster space for this player, you must choose a player to drop "
            ),
            _vm.waiversActive
              ? _c("span", [_vm._v("if your bid is successful")])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("h3", [_vm._v("Add player")]),
      _vm.waiversActive
        ? _c(
            "v-col",
            { attrs: { md: "2" } },
            [
              _c(
                "v-form",
                { ref: "bidForm" },
                [
                  _c("app-number-field", {
                    attrs: {
                      required: "",
                      label: "Waiver bid",
                      min: "0",
                      prependInnerIcon: "mdi-currency-usd",
                      rules: _vm.bidRules,
                      max: _vm.currentRoster.waiver_budget.toString(),
                    },
                    model: {
                      value: _vm.bid,
                      callback: function ($$v) {
                        _vm.bid = $$v
                      },
                      expression: "bid",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-simple-table",
        [
          [
            _c("thead", [
              _c("tr", [
                _c("th"),
                _c("th"),
                _c("th", [_vm._v("Player")]),
                _c("th", [_vm._v("Opp")]),
                _c("th", [_vm._v("Pos")]),
                _c("th", [_vm._v("GP")]),
                _c("th", [_vm._v("Rk")]),
                _c("th", [_vm._v("Points")]),
                _c("th", [_vm._v("Avg")]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _c(
                        "app-primary-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c("player-link", {
                        attrs: { player: _vm.player, leagueId: _vm.leagueId },
                      }),
                    ],
                    1
                  ),
                  _c("td", [_vm._v(_vm._s(_vm.getNextOpponent(_vm.player)))]),
                  _c("td", [_vm._v(_vm._s(_vm.player.position.toUpperCase()))]),
                  _c("td"),
                  _c("td"),
                  _c("td", [_vm._v(_vm._s(_vm.getSeasonPoints(_vm.player)))]),
                  _c("td"),
                ]),
                _c("tr", [_c("td", { attrs: { colspan: "9" } })]),
                _c("tr", [
                  _c("th", { attrs: { colspan: "9" } }, [
                    _vm._v("Drop player"),
                  ]),
                ]),
                _vm.canAddWithoutDrop
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "9" } },
                        [
                          _c(
                            "app-primary-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.confirmAddPlayer()
                                },
                              },
                            },
                            [_vm._v(" Don't drop anyone (roster is not full) ")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._l(_vm.dropTargets, function (spot) {
                  return _c("tr", { key: spot.id }, [
                    _c(
                      "td",
                      [
                        !_vm.canAddWithoutDrop
                          ? _c(
                              "app-primary-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmAddPlayer(spot)
                                  },
                                },
                              },
                              [_vm._v("Drop")]
                            )
                          : _vm._e(),
                        _vm.canAddWithoutDrop
                          ? _c(
                              "app-default-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmAddPlayer(spot)
                                  },
                                },
                              },
                              [_vm._v("Drop")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(spot.name))]),
                    _c(
                      "td",
                      [
                        _c("player-link", {
                          attrs: {
                            player: spot.player,
                            leagueId: _vm.leagueId,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _vm._v(_vm._s(_vm.getNextOpponent(spot.player))),
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(spot.player.position.toUpperCase())),
                    ]),
                    _c("td"),
                    _c("td"),
                    _c("td", [
                      _vm._v(_vm._s(_vm.getSeasonPoints(spot.player))),
                    ]),
                    _c("td"),
                  ])
                }),
              ],
              2
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }