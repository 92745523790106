var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "transactions" } },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "subtitle-1" }, [
            !_vm.showAll
              ? _c("span", [_vm._v("Last 10 league transactions")])
              : _c("h5", [_vm._v("All league transactions")]),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    _vm._l(_vm.transactions, function (transaction) {
                      return _c(
                        "p",
                        {
                          key: transaction.id,
                          staticClass: "transaction caption",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatTimestamp(transaction.timestamp)
                              ) +
                              " - "
                          ),
                          _c(
                            "span",
                            { class: _vm.getMessageClass(transaction) },
                            [_vm._v(_vm._s(transaction.message))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  !_vm.showAll
                    ? _c(
                        "v-btn",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              _vm.showAll = true
                            },
                          },
                        },
                        [_vm._v(" Show all")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }