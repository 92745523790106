var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c("app-select", {
            attrs: {
              label: "Week number",
              items: _vm.availableWeeks,
              required: "",
            },
            model: {
              value: _vm.weekNumber,
              callback: function ($$v) {
                _vm.weekNumber = $$v
              },
              expression: "weekNumber",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.week
            ? _c(
                "v-simple-table",
                [
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Roster")]),
                        _c("th", [_vm._v("Player")]),
                        _c("th", [_vm._v("Bid")]),
                        _c("th", [_vm._v("Drop Player")]),
                        _c("th", [_vm._v("Result")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.week.waiver_bids, function (bid, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _vm._v(" " + _vm._s(_vm.getRosterName(bid)) + " "),
                          ]),
                          _c(
                            "td",
                            [
                              _c("player-link", {
                                attrs: {
                                  leagueId: _vm.leagueId,
                                  player: bid.player,
                                  showTeamLogo: false,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", [_vm._v("$" + _vm._s(bid.amount))]),
                          _c(
                            "td",
                            [
                              bid.drop_player
                                ? _c("player-link", {
                                    attrs: {
                                      leagueId: _vm.leagueId,
                                      player: bid.drop_player,
                                      showTeamLogo: false,
                                    },
                                  })
                                : _c("span", [_vm._v("N/A")]),
                            ],
                            1
                          ),
                          _c("td", [
                            _vm._v(
                              " " + _vm._s(_vm.getBidResult(bid.result)) + " "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }