var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leagueId && _vm.weekNumber
    ? _c(
        "v-row",
        { staticClass: "heading mb-2 text-right" },
        [
          _c(
            "v-col",
            { staticClass: "roster-name pl-4", attrs: { cols: "5" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 caption roster-name",
                      class: _vm.awayHeaderClass,
                    },
                    [
                      _vm.away
                        ? _c(
                            "span",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "roster",
                                      params: {
                                        leagueId: _vm.leagueId,
                                        rosterId: _vm.matchup.away.id,
                                      },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.away.name) + " ")]
                              ),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("TBD")]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 text-h4 score",
                      class: _vm.awayScoreClass,
                    },
                    [
                      _c("roster-score", {
                        attrs: {
                          roster: _vm.away,
                          weekNumber: _vm.weekNumber,
                          calculatedScore: _vm.matchup.away_score,
                        },
                        on: { update: _vm.updateAwayScore },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 grey--text" },
                    [
                      _vm.enableProjections
                        ? _c("score", { attrs: { score: _vm.awayProjection } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showMatchupProgress && _vm.matchup
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("matchup-progress", {
                            staticClass: "pr-1",
                            attrs: {
                              roster: _vm.matchup.away,
                              reverse: true,
                              leagueId: _vm.leagueId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c(
                "v-row",
                [_c("v-col", { staticClass: "pb-0" }, [_vm._v("vs")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "roster-name pr-4 text-left", attrs: { cols: "5" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 caption roster-name",
                      class: _vm.homeHeaderClass,
                    },
                    [
                      _vm.matchup.home
                        ? _c(
                            "span",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "roster",
                                      params: {
                                        leagueId: _vm.leagueId,
                                        rosterId: _vm.matchup.home.id,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.matchup.home.name) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("TBD")]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 text-h4", class: _vm.homeScoreClass },
                    [
                      _c("roster-score", {
                        attrs: {
                          roster: _vm.home,
                          weekNumber: _vm.weekNumber,
                          calculatedScore: _vm.matchup.home_score,
                        },
                        on: { update: _vm.updateHomeScore },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0 grey--text" },
                    [
                      _vm.enableProjections
                        ? _c("score", { attrs: { score: _vm.homeProjection } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showMatchupProgress && _vm.matchup.home
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("matchup-progress", {
                            staticClass: "pr-1'",
                            attrs: {
                              roster: _vm.matchup.home,
                              reverse: false,
                              leagueId: _vm.leagueId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }