var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-simple-table",
    [
      [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Name")]),
            _c("th", [_vm._v("ID")]),
            _c("th"),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.problemLeagues, function (league) {
            return _c("tr", { key: league.id }, [
              _c("td", [_vm._v(_vm._s(league.name))]),
              _c("td", [_vm._v(_vm._s(league.id))]),
              _c(
                "td",
                [
                  _c(
                    "app-primary-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.createSubscriptions(league)
                        },
                      },
                    },
                    [_vm._v("Fix")]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }