var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.trySignup()
        },
      },
    },
    [
      _c("app-text-field", {
        attrs: { label: "League ID", required: "" },
        model: {
          value: _vm.leagueId,
          callback: function ($$v) {
            _vm.leagueId = $$v
          },
          expression: "leagueId",
        },
      }),
      _c("app-text-field", {
        attrs: { label: "Password" },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("app-primary-button", [_vm._v("Join")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }