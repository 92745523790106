var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league && _vm.scoringInfo
    ? _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "mt-4" },
            [
              _c("v-card-title", [_vm._v("Scoring settings")]),
              _c(
                "v-card-text",
                [
                  _c("v-simple-table", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _vm._l(
                                _vm.scoringInfo.sections,
                                function (section, index) {
                                  return [
                                    _c("thead", { key: "header-" + index }, [
                                      _c("tr", [
                                        _c("th", { attrs: { colspan: "1" } }, [
                                          _vm._v(_vm._s(section.description)),
                                        ]),
                                        _c("th", [_vm._v("Value")]),
                                        _c("th"),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      { key: index },
                                      [
                                        _vm._l(
                                          section.actions,
                                          function (action) {
                                            return _c(
                                              "tr",
                                              { key: action.id },
                                              [
                                                _c(
                                                  "td",
                                                  { staticClass: "action" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(action.description)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  { staticClass: "value" },
                                                  [
                                                    _c("app-form-label", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.scoringConfig[
                                                            action.id
                                                          ]
                                                        )
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c("td", [
                                                  action.show_yards_per_point
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.calculateYardsPerPoint(
                                                                _vm
                                                                  .scoringConfig[
                                                                  action.id
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        _c("tr", [
                                          _c("td", { attrs: { colspan: "3" } }),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ]
                                }
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3660528298
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card", { staticClass: "mt-5" }, [_c("v-card-text")], 1),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }