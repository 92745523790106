var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Last Pick")]),
      _vm.previousSlot
        ? _c("v-card-text", [
            _vm._v(" " + _vm._s(_vm.previousSlot.result) + " "),
          ])
        : _c("v-card-text", [_vm._v(" N/A ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }