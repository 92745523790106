var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", { attrs: { to: "/" } }, [
    _c("img", {
      staticClass: "logo-small",
      attrs: {
        src: require("@/assets/img/football-white.png"),
        alt: "110 yards",
      },
    }),
    _c("label", { staticClass: "d-sr-only" }, [_vm._v("Home")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }