var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.games
    ? _c("v-container", [
        _c(
          "table",
          { staticClass: "scoreboard" },
          _vm._l(_vm.games, function (game) {
            return _c("tbody", { key: game.id, staticClass: "score" }, [
              _c("tr", { staticClass: "away" }, [
                _c("td", { staticClass: "team", class: _vm.awayClass(game) }, [
                  _vm._v(_vm._s(game.away.abbr)),
                ]),
                _c("td", { staticClass: "pts", class: _vm.awayClass(game) }, [
                  _vm._v(_vm._s(game.away_score)),
                ]),
                _c("td", { staticClass: "caption" }, [
                  _vm._v(_vm._s(_vm.gameStatus(game))),
                ]),
              ]),
              _c("tr", { staticClass: "home" }, [
                _c("td", { staticClass: "team", class: _vm.homeClass(game) }, [
                  _vm._v(_vm._s(game.home.abbr)),
                ]),
                _c("td", { staticClass: "pts", class: _vm.homeClass(game) }, [
                  _vm._v(_vm._s(game.home_score)),
                ]),
                _c("td", { staticClass: "caption" }, [
                  _vm._v(_vm._s(_vm.gameStatusLine2(game))),
                ]),
              ]),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }