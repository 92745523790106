import { render, staticRenderFns } from "./Matchup.vue?vue&type=template&id=3e948d4b&scoped=true&"
import script from "./Matchup.vue?vue&type=script&lang=js&"
export * from "./Matchup.vue?vue&type=script&lang=js&"
import style0 from "./Matchup.vue?vue&type=style&index=0&id=3e948d4b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e948d4b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAlert,VBtn,VCol,VForm,VRow,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fantasy/fantasy/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e948d4b')) {
      api.createRecord('3e948d4b', component.options)
    } else {
      api.reload('3e948d4b', component.options)
    }
    module.hot.accept("./Matchup.vue?vue&type=template&id=3e948d4b&scoped=true&", function () {
      api.rerender('3e948d4b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/league/matchup/Matchup.vue"
export default component.exports