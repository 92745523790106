var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "header", dark: "", short: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.showDrawer = !_vm.showDrawer
              },
            },
          }),
          _c("v-spacer"),
          _c("home-link"),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { color: "header", app: "", left: "", temporary: "" },
          model: {
            value: _vm.showDrawer,
            callback: function ($$v) {
              _vm.showDrawer = $$v
            },
            expression: "showDrawer",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c("v-list-item", [_c("v-list-item-title")], 1),
              _vm.hasLeague
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: _vm.showLeagueSubMenu },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("league-link", {
                                          staticClass: "nav-primary",
                                          attrs: { leagueId: _vm.leagueId },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3599139782
                      ),
                    },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "ml-4" },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "nav-secondary",
                                  attrs: {
                                    text: "",
                                    small: "",
                                    to: {
                                      name: "league-settings",
                                      params: { leagueId: _vm.leagueId },
                                    },
                                  },
                                },
                                [_vm._v(" Scoring ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { staticClass: "ml-4" },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "nav-secondary",
                                  attrs: {
                                    text: "",
                                    small: "",
                                    to: {
                                      name: "league-schedule",
                                      params: { leagueId: _vm.leagueId },
                                    },
                                  },
                                },
                                [_vm._v(" Schedule ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isCommissioner || _vm.isAdmin
                        ? _c(
                            "v-list-item",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "nav-secondary",
                                      attrs: {
                                        small: "",
                                        text: "",
                                        to: {
                                          name: "commissioner",
                                          params: { leagueId: _vm.leagueId },
                                        },
                                      },
                                    },
                                    [_vm._v(" Commissioner ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAdmin
                        ? _c(
                            "v-list-item",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "nav-secondary",
                                      attrs: {
                                        small: "",
                                        text: "",
                                        to: {
                                          name: "league-admin",
                                          params: { leagueId: _vm.leagueId },
                                        },
                                      },
                                    },
                                    [_vm._v(" Admin ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasNotes
                        ? _c(
                            "v-list-item",
                            { staticClass: "ml-4" },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "nav-secondary",
                                      attrs: {
                                        text: "",
                                        small: "",
                                        to: {
                                          name: "league-notes",
                                          params: { leagueId: _vm.leagueId },
                                        },
                                      },
                                    },
                                    [_vm._v(" Notes ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasRoster
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("roster-link", {
                                staticClass: "nav-primary",
                                attrs: {
                                  leagueId: _vm.leagueId,
                                  userId: _vm.userId,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasLeague
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("players-link", {
                                staticClass: "nav-primary",
                                attrs: { leagueId: _vm.leagueId },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAnonymous
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("profile-link", {
                                staticClass: "nav-primary",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [_c("support-link", { staticClass: "nav-primary" })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [_c("faq-link", { staticClass: "nav-primary" })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [_c("admin-link", { staticClass: "nav-primary" })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMod
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [_c("mod-link", { staticClass: "nav-primary" })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [_c("discord-link", { staticClass: "nav-primary" })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isAnonymous
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [_c("log-in-link", { staticClass: "nav-primary" })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isAnonymous
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("log-out-link", {
                                staticClass: "nav-primary",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }