var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "400px" } },
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
      !_vm.linkSent && _vm.enableLogin
        ? _c(
            "v-card-text",
            [
              _c(
                "p",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.login.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("app-text-field", {
                        attrs: {
                          label: "Email",
                          rules: _vm.emailRules,
                          autocomplete: "email",
                          required: "",
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _c("app-primary-button", { attrs: { block: "" } }, [
                        _vm._v("Send login link"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                { staticClass: "text-center" },
                [_c("why-passwordless")],
                1
              ),
              _c("p", { staticClass: "text-center" }, [_vm._v("OR")]),
              _c("social", { staticClass: "text-center" }),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _vm._v(" Not signed up yet? "),
                  _c("router-link", { attrs: { to: "/signup" } }, [
                    _vm._v("Sign Up"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.linkSent
        ? _c("v-card-text", [
            _c("p", { staticClass: "text-left" }, [
              _vm._v(
                " A sign in link has been sent to " +
                  _vm._s(this.email) +
                  ", please check your email and click the link to log in. "
              ),
            ]),
            _c("p", [_c("why-passwordless")], 1),
          ])
        : _vm._e(),
      !_vm.enableLogin
        ? _c("v-card-text", [
            _c("p", { staticClass: "text-left" }, [
              _vm._v(
                "Logging in to the site is currently disabled, please try again later."
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }