var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "mb-2",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("Close")]
      ),
      _c(
        "pre",
        { attrs: { id: "dataTable" } },
        [
          _vm._v(_vm._s(_vm.headers) + "\n"),
          _vm._l(_vm.data, function (player) {
            return [
              _vm._l(_vm.keys, function (key) {
                return [_vm._v(_vm._s(_vm.getValue(player, key)) + ",")]
              }),
              _c("br", { key: player.id }),
            ]
          }),
          _vm._v("\n  "),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }