var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.hasDuplicates
        ? _c("v-alert", { staticClass: "mx-3", attrs: { type: "error" } }, [
            _vm._v(
              " This league has been affected by a bug which allowed rosters to show up multiple times in the draft order. Click the update button to fix the problem. "
            ),
          ])
        : _vm._e(),
      _vm.blankEntries
        ? _c("v-alert", { staticClass: "mx-3", attrs: { type: "error" } }, [
            _vm._v(
              " This league has been affected by a bug which caused removed teams to remain in the draft order. Click the update button to fix the problem. "
            ),
          ])
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { md: "6", col: "12" } },
        [
          _c(
            "ol",
            [
              _c(
                "draggable",
                {
                  on: {
                    start: function ($event) {
                      _vm.drag = true
                    },
                    end: function ($event) {
                      _vm.drag = false
                    },
                  },
                  model: {
                    value: _vm.draftOrder,
                    callback: function ($$v) {
                      _vm.draftOrder = $$v
                    },
                    expression: "draftOrder",
                  },
                },
                _vm._l(_vm.draftOrder, function (element) {
                  return _c(
                    "li",
                    { key: element.roster_id, staticClass: "roster" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.getRosterName(element.roster_id)) + " "
                      ),
                      _c("v-icon", { staticClass: "float-right" }, [
                        _vm._v("mdi-menu"),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "app-primary-button",
            { attrs: { type: "button" }, on: { click: _vm.save } },
            [_vm._v(" Update ")]
          ),
          _c("saved-indicator", { attrs: { saved: _vm.saved } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }