var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.progress
        ? _c("span", { staticClass: "caption grey--text" }, [
            _vm._v(_vm._s(_vm.progress.remaining) + " games left"),
          ])
        : _vm._e(),
      _vm.progress
        ? _c("v-progress-linear", {
            attrs: {
              value: _vm.progress.percent_complete,
              reverse: _vm.reverse,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }