var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _vm.schedule
            ? _c(
                "v-carousel",
                {
                  attrs: {
                    "hide-delimiters": "",
                    "show-arrows-on-hover": true,
                    height: "auto",
                  },
                  model: {
                    value: _vm.selectedWeekIndex,
                    callback: function ($$v) {
                      _vm.selectedWeekIndex = $$v
                    },
                    expression: "selectedWeekIndex",
                  },
                },
                _vm._l(_vm.schedule.weeks, function (week) {
                  return _c(
                    "v-carousel-item",
                    { key: week.week_number },
                    [
                      _c(
                        "v-sheet",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12" },
                                },
                                [_vm._v(" " + _vm._s(week.heading) + " ")]
                              ),
                            ],
                            1
                          ),
                          _vm._l(week.matchups, function (matchup) {
                            return _c("matchup-preview", {
                              key: matchup.id,
                              staticClass: "matchup",
                              attrs: {
                                leagueId: _vm.leagueId,
                                matchup: matchup,
                                weekNumber: week.week_number,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }