var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.draftSlot
    ? _c(
        "v-card",
        [
          _c("v-card-title", [
            _vm._v("Auction Draft, Pick " + _vm._s(_vm.draftSlot.pick_number)),
          ]),
          !_vm.biddingStarted
            ? _c("v-card-text", [
                _c("div", [
                  _vm._v(
                    "Nominator: " +
                      _vm._s(_vm.getRosterName(_vm.draftSlot.nominator))
                  ),
                ]),
                _c("div", [_vm._v("Player: TBD")]),
              ])
            : _vm._e(),
          _vm.biddingStarted
            ? _c("v-card-text", [
                _c(
                  "div",
                  [
                    _vm._v(" Player: "),
                    _vm.draftSlot.player
                      ? _c("player-link", {
                          attrs: {
                            leagueId: _vm.leagueId,
                            player: _vm.draftSlot.player,
                          },
                        })
                      : _vm._e(),
                    _vm._v(
                      " nominated by " +
                        _vm._s(_vm.getRosterName(_vm.draftSlot.nominator)) +
                        " "
                    ),
                  ],
                  1
                ),
                _c("div", [
                  _vm._v(
                    "Bid: $" +
                      _vm._s(_vm.draftSlot.bid ? _vm.draftSlot.bid : 0) +
                      " - " +
                      _vm._s(_vm.getRosterName(_vm.draftSlot.roster_id))
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.biddingStarted && !_vm.isPaused
            ? _c(
                "v-card-text",
                _vm._l(_vm.draftSlot.bidders, function (bidder) {
                  return _c("div", { key: bidder.index }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getRosterName(bidder.roster_id)) +
                        " - " +
                        _vm._s(_vm.getBidderStatus(bidder)) +
                        " "
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.biddingStarted && _vm.isPaused && _vm.isCommissioner
            ? _c("v-card-text", [
                _vm._v(
                  " TODO: Allow commissioner to force a winning bid for a user "
                ),
              ])
            : _vm._e(),
          _vm.tip && !_vm.isPaused
            ? _c("v-card-text", { staticClass: "warning--text" }, [
                _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))]),
              ])
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _vm.yourTurn
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              type: "number",
                              outlined: "",
                              filled: "",
                              dense: "",
                              required: "",
                              max: _vm.maxBid,
                              min: _vm.minBid,
                            },
                            model: {
                              value: _vm.bid,
                              callback: function ($$v) {
                                _vm.bid = _vm._n($$v)
                              },
                              expression: "bid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "app-primary-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.placeBid()
                                },
                              },
                            },
                            [_vm._v("Bid")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "app-default-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.passBid()
                                },
                              },
                            },
                            [_vm._v("Pass")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }