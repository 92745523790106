var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.link
    ? _c("v-btn", { attrs: { text: "", href: _vm.link, target: "_blank" } }, [
        _vm._v("Discord"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }