<template>
  <div id="footer">
    <p>&copy; 2021 Michael Dryden</p>
  </div>
</template>

<style scoped>
#footer {
  padding-top: 2em;
  margin: auto;
  text-align: center;
  font-size: small;
  color: gray;
}
</style>

<script>
export default {
  name: "AppFooter",
}
</script>
