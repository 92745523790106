var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _vm._l(
                          _vm.scoringInfo.sections,
                          function (section, index) {
                            return [
                              _c("thead", { key: "header-" + index }, [
                                _c("tr", [
                                  _c("th", { attrs: { colspan: "3" } }, [
                                    _vm._v(_vm._s(section.description)),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                { key: index },
                                [
                                  _vm._l(section.actions, function (action) {
                                    return _c("tr", { key: action.id }, [
                                      _c("td", { staticClass: "action" }, [
                                        _vm._v(_vm._s(action.description)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "value" },
                                        [
                                          !_vm.readonly
                                            ? _c("app-number-field", {
                                                attrs: {
                                                  type: "number",
                                                  "data-action-id": action.id,
                                                  rules: [
                                                    function (v) {
                                                      return (
                                                        v !== "" || "Required"
                                                      )
                                                    },
                                                  ],
                                                },
                                                model: {
                                                  value:
                                                    _vm.leagueScoring[
                                                      action.id
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.leagueScoring,
                                                      action.id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "leagueScoring[action.id]",
                                                },
                                              })
                                            : _c("app-form-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.leagueScoring[action.id]
                                                  )
                                                ),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        action.show_yards_per_point
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.calculateYardsPerPoint(
                                                      _vm.leagueScoring[
                                                        action.id
                                                      ]
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                    ])
                                  }),
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "3" } }),
                                  ]),
                                ],
                                2
                              ),
                            ]
                          }
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              !_vm.isWeek1 && _vm.leagueStarted && !_vm.readonly
                ? _c("v-alert", { attrs: { type: "warning" } }, [
                    _vm._v(
                      "Changing scoring settings will not affect results for previous weeks"
                    ),
                  ])
                : _vm._e(),
              !_vm.readonly
                ? _c("app-primary-button", [_vm._v("Save changes")])
                : _vm._e(),
              _c("saved-indicator", { attrs: { saved: _vm.saved } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }