var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Commissioner tools")]),
      _vm.draft.is_paused && !_vm.endDraftClicked
        ? _c("v-card-subtitle", [
            _vm._v(
              " The draft is currently paused. While paused, you may undo picks or reset the draft. To reset the draft, you must first undo all picks. "
            ),
          ])
        : _vm._e(),
      !_vm.draft.is_paused
        ? _c(
            "v-card-text",
            [
              _c("app-default-button", { on: { click: _vm.pauseDraft } }, [
                _vm._v("Pause Draft"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.draft.is_paused && !_vm.endDraftClicked
        ? _c(
            "v-card-text",
            [
              _vm.draft.is_paused
                ? _c(
                    "app-primary-button",
                    { staticClass: "mr-2", on: { click: _vm.resumeDraft } },
                    [_vm._v("Resume Draft")]
                  )
                : _vm._e(),
              _c(
                "app-default-button",
                { staticClass: "mr-2", on: { click: _vm.undoLastPick } },
                [_vm._v("Undo Last Pick")]
              ),
              _c(
                "app-default-button",
                { staticClass: "mr-2", on: { click: _vm.resetDraft } },
                [_vm._v("Reset Draft")]
              ),
              _vm.isAuction
                ? _c("app-default-button", { staticClass: "mr-2" }, [
                    _vm._v("Clear nomination"),
                  ])
                : _vm._e(),
              _c(
                "app-default-button",
                { staticClass: "mr-2", on: { click: _vm.confirmEndDraft } },
                [_vm._v("End Draft")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.endDraftClicked
        ? _c("v-card-subtitle", [
            _vm._v(
              " Are you sure you want to end the draft early? This cannot be undone. "
            ),
          ])
        : _vm._e(),
      _vm.endDraftClicked
        ? _c(
            "v-card-text",
            [
              _vm.endDraftClicked
                ? _c(
                    "app-primary-button",
                    {
                      staticClass: "mr-2",
                      on: {
                        click: function ($event) {
                          _vm.endDraftClicked = false
                        },
                      },
                    },
                    [_vm._v("No, continue draft")]
                  )
                : _vm._e(),
              _vm.endDraftClicked
                ? _c(
                    "app-default-button",
                    { staticClass: "mr-2", on: { click: _vm.endDraft } },
                    [_vm._v(" Yes, end draft early ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }