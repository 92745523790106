var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCommissioner && _vm.league != null
    ? _c(
        "div",
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Commmissioner Tools")]),
              _c(
                "v-card-subtitle",
                [
                  _vm.showDraftNotReadyTip
                    ? _c("v-alert", { attrs: { type: "info" } }, [
                        _vm._v("Generate a schedule to enable the draft"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("registration")
                        },
                      },
                    },
                    [_vm._v("Registration")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("league-options")
                        },
                      },
                    },
                    [_vm._v("League options")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("scoring")
                        },
                      },
                    },
                    [_vm._v("Scoring")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("manage-teams")
                        },
                      },
                    },
                    [_vm._v("Manage Teams")]
                  ),
                  _vm.hasDraftOrder && !_vm.leagueStarted
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "caption",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setView("draft-order")
                            },
                          },
                        },
                        [_vm._v(" Draft Order ")]
                      )
                    : _vm._e(),
                  _vm.isAuction
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "caption",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setView("budgets")
                            },
                          },
                        },
                        [_vm._v("Draft Budgets")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("rosters")
                        },
                      },
                    },
                    [_vm._v("Roster options")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("schedule")
                        },
                      },
                    },
                    [_vm._v("Schedule")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "caption",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.setView("notes")
                        },
                      },
                    },
                    [_vm._v("Notes")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _vm.view == "registration"
                    ? _c("registration", {
                        attrs: {
                          league: _vm.league,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "league-options"
                    ? _c("league-options", {
                        attrs: {
                          league: _vm.league,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "scoring"
                    ? _c("scoring-settings", {
                        attrs: {
                          leagueId: _vm.leagueId,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "manage-teams"
                    ? _c("manage-teams", { attrs: { leagueId: _vm.leagueId } })
                    : _vm._e(),
                  _vm.view == "draft-order"
                    ? _c("draft-order", {
                        attrs: {
                          league: _vm.league,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "budgets"
                    ? _c("budgets", {
                        attrs: {
                          league: _vm.league,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "rosters"
                    ? _c("rosters", {
                        attrs: {
                          leagueId: _vm.leagueId,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "schedule"
                    ? _c("schedule", {
                        attrs: {
                          leagueId: _vm.leagueId,
                          leagueStarted: _vm.leagueStarted,
                        },
                      })
                    : _vm._e(),
                  _vm.view == "notes"
                    ? _c("league-notes", { attrs: { league: _vm.league } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }