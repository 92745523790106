var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.players
    ? _c("v-data-table", {
        attrs: {
          "mobile-breakpoint": "0",
          items: _vm.players,
          headers: _vm.headers,
          "hide-default-footer": "true",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
            {
              key: "item.actions",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm.showActions
                    ? _c(
                        "div",
                        [
                          !_vm.isLocked(item)
                            ? _c(
                                "v-icon",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPlayer(item)
                                    },
                                  },
                                },
                                [_vm._v("mdi-plus")]
                              )
                            : _vm._e(),
                          _vm.isLocked(item) ? _c("locked") : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "item.display_name",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c("player-link", {
                    attrs: { player: item, leagueId: _vm.leagueId },
                  }),
                ]
              },
            },
            {
              key: "item.opponent",
              fn: function (ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getNextOpponent(item)) + " ")]
              },
            },
            {
              key: "item.position",
              fn: function (ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.position.toUpperCase()) + " ")]
              },
            },
            {
              key: "item.points",
              fn: function (ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(_vm.getSeasonPoints(item)) + " ")]
              },
            },
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }