var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.leaguePositions && _vm.rosterPositions
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c("app-form-label", [
                    _vm._v("Allow Quarterbacks on bench?"),
                  ]),
                  _c(
                    "v-layout",
                    { staticClass: "mb-8", attrs: { "align-center": "" } },
                    [
                      _c("v-simple-checkbox", {
                        staticClass: "form-check-input",
                        attrs: { ripple: false },
                        model: {
                          value: _vm.leaguePositions.allow_bench_qb,
                          callback: function ($$v) {
                            _vm.$set(_vm.leaguePositions, "allow_bench_qb", $$v)
                          },
                          expression: "leaguePositions.allow_bench_qb",
                        },
                      }),
                      _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                  _c("app-form-label", [
                    _vm._v("Allow Runningbacks on bench?"),
                  ]),
                  _c(
                    "v-layout",
                    { staticClass: "mb-8", attrs: { "align-center": "" } },
                    [
                      _c("v-simple-checkbox", {
                        staticClass: "form-check-input",
                        attrs: { ripple: false },
                        model: {
                          value: _vm.leaguePositions.allow_bench_rb,
                          callback: function ($$v) {
                            _vm.$set(_vm.leaguePositions, "allow_bench_rb", $$v)
                          },
                          expression: "leaguePositions.allow_bench_rb",
                        },
                      }),
                      _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                  _c("app-form-label", [_vm._v("Allow Kickers on bench?")]),
                  _c(
                    "v-layout",
                    { staticClass: "mb-8", attrs: { "align-center": "" } },
                    [
                      _c("v-simple-checkbox", {
                        staticClass: "form-check-input",
                        attrs: { ripple: false },
                        model: {
                          value: _vm.leaguePositions.allow_bench_k,
                          callback: function ($$v) {
                            _vm.$set(_vm.leaguePositions, "allow_bench_k", $$v)
                          },
                          expression: "leaguePositions.allow_bench_k",
                        },
                      }),
                      _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                  _vm.notLimitingRarePositions
                    ? _c("v-alert", { attrs: { type: "warning" } }, [
                        _vm._v(
                          " Allowing limited position players (QB, RB or K) on bench may allow teams to stockpile and affect league balance. "
                        ),
                      ])
                    : _c("v-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          " Quarterback, running back and kicker slots are capped at 1 per roster unless they are in a Bye or IR reserve slot. "
                        ),
                      ]),
                  _c("v-simple-table", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Position")]),
                                  _c("th", [_vm._v("Count")]),
                                  _c("th"),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.rosterPositions,
                                  function (position) {
                                    return _c("tr", { key: position.id }, [
                                      _c("th", { staticClass: "position" }, [
                                        _vm._v(_vm._s(position.display)),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "count" },
                                        [
                                          !_vm.leagueStarted
                                            ? _c("app-number-field", {
                                                attrs: {
                                                  type: "number",
                                                  "data-position-id":
                                                    position.id,
                                                  max: _vm.getMax(position),
                                                },
                                                model: {
                                                  value:
                                                    _vm.leaguePositions[
                                                      position.id
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.leaguePositions,
                                                      position.id,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "leaguePositions[position.id]",
                                                },
                                              })
                                            : _c("app-form-label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.leaguePositions[
                                                      position.id
                                                    ]
                                                  )
                                                ),
                                              ]),
                                        ],
                                        1
                                      ),
                                      _c("td", { staticClass: "description" }, [
                                        _vm._v(_vm._s(position.description)),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      731381274
                    ),
                  }),
                  !_vm.leagueStarted
                    ? _c("app-primary-button", [_vm._v("Save changes")])
                    : _vm._e(),
                  _c("saved-indicator", { attrs: { saved: _vm.saved } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }