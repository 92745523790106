var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: "https://github.com/mdryden/110yards/wiki#why-does-110-yards-use-sign-in-links",
        target: "_blank",
      },
    },
    [_vm._v("Why not a password?")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }