var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        text: "",
        href: "https://github.com/mdryden/110yards/issues",
        target: "_blank",
      },
    },
    [_vm._v("Support")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }