<template>
  <router-link to="/">
    <img class="logo-small" src="@/assets/img/football-white.png" alt="110 yards" />
    <label class="d-sr-only">Home</label>
  </router-link>
</template>

<style scoped>
.logo-small {
  height: 40px;
}
</style>

<script>
export default {}
</script>
