var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.game && _vm.playerTeam
    ? _c("td", { staticClass: "text-no-wrap" }, [
        _c("a", { attrs: { href: _vm.cflLink, target: "_blank" } }, [
          _c("span", {}, [_vm._v(_vm._s(_vm.date) + " ")]),
          _c("span", { staticClass: "d-none d-md-inline pl-1" }, [
            _vm._v(_vm._s(_vm.result) + " " + _vm._s(_vm.opponent)),
          ]),
          _c("span", { staticClass: "d-md-none" }, [
            _vm._v("(" + _vm._s(_vm.letterResult) + ")"),
          ]),
        ]),
      ])
    : _c("td")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }