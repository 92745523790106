var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.player
    ? _c(
        "span",
        { staticClass: "player", attrs: { "data-player-id": _vm.player.id } },
        [
          !_vm.reverse
            ? [
                _vm.showTeamLogo
                  ? _c("span", { staticClass: "team-icon", class: _vm.team })
                  : _vm._e(),
                _vm.enablePlayerLinks
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "league-player",
                            params: {
                              leagueId: _vm.leagueId,
                              playerId: _vm.player.id,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
                    )
                  : _c("span", { class: _vm.playerNameClass }, [
                      _vm._v(" " + _vm._s(_vm.displayName) + " "),
                    ]),
                _vm.showTeamName
                  ? _c("span", { staticClass: "pl-1" }, [
                      _vm._v(
                        _vm._s(
                          _vm.player.team.abbr || _vm.player.team.abbreviation
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm.showPosition
                  ? _c("span", { staticClass: "pl-1" }, [
                      _vm._v(" - " + _vm._s(_vm.player.position.toUpperCase())),
                    ])
                  : _vm._e(),
                _vm.showPlayerStatus && !_vm.showShortPlayerStatus
                  ? _c(
                      "span",
                      {
                        staticClass: "pl-1",
                        class: _vm.injuryTextColor,
                        attrs: { title: _vm.injuryDetails },
                      },
                      [_vm._v(" " + _vm._s(_vm.injuryReport) + " ")]
                    )
                  : _vm._e(),
                _vm.showShortPlayerStatus && _vm.isInjured
                  ? _c(
                      "v-icon",
                      { attrs: { color: _vm.injuryIconColor, small: "" } },
                      [_vm._v(_vm._s(_vm.injuryIcon))]
                    )
                  : _vm._e(),
                _vm.showNational
                  ? _c("national-status", {
                      attrs: { national_status: _vm.player.national_status },
                    })
                  : _vm._e(),
              ]
            : [
                _vm.showNational
                  ? _c("national-status", {
                      attrs: { national_status: _vm.player.national_status },
                    })
                  : _vm._e(),
                _vm.showShortPlayerStatus && _vm.isInjured
                  ? _c(
                      "v-icon",
                      { attrs: { color: _vm.injuryIconColor, small: "" } },
                      [_vm._v(_vm._s(_vm.injuryIcon))]
                    )
                  : _vm._e(),
                _vm.showPlayerStatus && !_vm.showShortPlayerStatus
                  ? _c(
                      "span",
                      {
                        class: _vm.injuryTextColor,
                        attrs: { title: _vm.injuryDetails },
                      },
                      [_vm._v(" " + _vm._s(_vm.injuryReport) + " ")]
                    )
                  : _vm._e(),
                _vm.enablePlayerLinks
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "league-player",
                            params: {
                              leagueId: _vm.leagueId,
                              playerId: _vm.player.id,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
                    )
                  : _c("span", { class: _vm.playerNameClass }, [
                      _vm._v(" " + _vm._s(_vm.displayName) + " "),
                    ]),
                _vm.showTeamName
                  ? _c("span", {}, [_vm._v(_vm._s(_vm.player.team.abbr))])
                  : _vm._e(),
                _vm.showPosition
                  ? _c("span", {}, [
                      _vm._v(" - " + _vm._s(_vm.player.position.toUpperCase())),
                    ])
                  : _vm._e(),
                _vm.showTeamLogo
                  ? _c("span", {
                      staticClass: "team-icon pl-1",
                      class: _vm.team,
                    })
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }