var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.archiveLeagues
    ? _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v("Play again in " + _vm._s(_vm.currentSeason) + ":"),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      " Renewing a league will maintain your settings and past season results. Managers who played in the previous season will automatically be registered in your new league. All settings can be changed prior to the start of the season. "
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    _vm._l(_vm.archiveLeagues, function (league) {
                      return _c(
                        "div",
                        { key: league.leagueId },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "title", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "league",
                                          params: { leagueId: league.id },
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(league.league_name))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }