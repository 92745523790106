var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _vm.editRosterId
            ? _c(
                "div",
                [
                  _c("app-text-field", {
                    attrs: { label: "Roster name" },
                    model: {
                      value: _vm.editRosterName,
                      callback: function ($$v) {
                        _vm.editRosterName = $$v
                      },
                      expression: "editRosterName",
                    },
                  }),
                  _c(
                    "app-primary-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.updateRosterName()
                        },
                      },
                    },
                    [_vm._v("Change name")]
                  ),
                  _c(
                    "app-default-button",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          _vm.editRosterId = null
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editBudgetRoster
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(" Edit waiver budget for "),
                    _c("strong", [_vm._v(_vm._s(_vm.editBudgetRoster.name))]),
                  ]),
                  _c("app-number-field", {
                    attrs: { min: "0", label: "Waiver budget" },
                    model: {
                      value: _vm.editBudgetAmount,
                      callback: function ($$v) {
                        _vm.editBudgetAmount = $$v
                      },
                      expression: "editBudgetAmount",
                    },
                  }),
                  _c(
                    "app-primary-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.updateRosterBudget()
                        },
                      },
                    },
                    [_vm._v("Update budget")]
                  ),
                  _c(
                    "app-default-button",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          _vm.editBudgetRoster = null
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.banRoster
            ? _c(
                "div",
                [
                  !_vm.banRoster.name_changes_banned
                    ? _c("p", [
                        _vm._v(" Prevent "),
                        _c("strong", [_vm._v(_vm._s(_vm.banRoster.name))]),
                        _vm._v(" from changing roster name? "),
                      ])
                    : _c("p", [
                        _vm._v(" Allow "),
                        _c("strong", [_vm._v(_vm._s(_vm.banRoster.name))]),
                        _vm._v(" to change roster name again? "),
                      ]),
                  _c(
                    "app-primary-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.flipNameChangesBan()
                        },
                      },
                    },
                    [_vm._v("Yes")]
                  ),
                  _c(
                    "app-default-button",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          _vm.banRoster = null
                        },
                      },
                    },
                    [_vm._v("No, cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.transferRosterId
            ? _c(
                "div",
                [
                  _c(
                    "v-alert",
                    {
                      attrs: { dense: "", icon: "mdi-alert", type: "warning" },
                    },
                    [
                      _vm._v(
                        " Transfering ownership of a roster from one user to another will remove the current user from your league. If you're sure you wish to continue, please enter the email address of the new owner. The new owner should already be a member of 110yards. If the new owner has not yet registered, please have them do so before continuing. "
                      ),
                    ]
                  ),
                  _vm.uid == _vm.transferRosterId
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            dense: "",
                            icon: "mdi-alert-octagon",
                            type: "error",
                          },
                        },
                        [
                          _vm._v(
                            " Danger! You have selected your own roster to be transfered!!! If you do this, the new user will become the commissioner of the league and you will no longer have access to this league. "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("strong", [
                    _vm._v(
                      "Transfer " + _vm._s(_vm.transferRosterName) + " to:"
                    ),
                  ]),
                  _c("app-text-field", {
                    attrs: { label: "New owner's email" },
                    model: {
                      value: _vm.transferRosterEmail,
                      callback: function ($$v) {
                        _vm.transferRosterEmail = $$v
                      },
                      expression: "transferRosterEmail",
                    },
                  }),
                  _c(
                    "app-default-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.transferOwnership()
                        },
                      },
                    },
                    [_vm._v("Confirm transfer")]
                  ),
                  _c(
                    "app-primary-button",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          _vm.transferRosterId = null
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showTable
            ? _c("v-simple-table", {
                staticClass: "table",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Name")]),
                              _c("th", [_vm._v("Waiver budget")]),
                              _c("th"),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.rosters, function (roster) {
                              return _c("tr", { key: roster.id }, [
                                _c("td", [
                                  _c("span", [_vm._v(_vm._s(roster.name))]),
                                ]),
                                _c("td", [
                                  _vm._v("$" + _vm._s(roster.waiver_budget)),
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "test-right text-no-wrap" },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      { attrs: { icon: "" } },
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.beginEditRosterName(
                                                      roster
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v("Change name"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    _vm.banRoster = roster
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  !roster.name_changes_banned
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "Block name changes"
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "Allow name changes"
                                                        ),
                                                      ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.beginEditBudget(
                                                      roster
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v("Adjust budget"),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm.canRemove(roster)
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.confirmRemoval(
                                                          roster
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        " Remove from league "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.confirmTransferOwnership(
                                                      roster
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " Transfer ownership "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  755190851
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }