var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12", md: "10" } }, [
        _c("h2", { staticClass: "brand" }, [_vm._v(_vm._s(_vm.leagueName))]),
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "2" } },
        [
          _c("season-list", {
            attrs: { leagueId: _vm.leagueId, season: _vm.season },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }