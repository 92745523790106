var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showTable
    ? _c(
        "div",
        [
          _vm.playerToAdd
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("add-player", {
                    attrs: {
                      waiversActive: _vm.waiversActive,
                      player: _vm.playerToAdd,
                      currentRoster: _vm.currentRoster,
                      leagueId: _vm.leagueId,
                      players: _vm.players,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.playerToAdd = null
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "v-card",
                { staticClass: "player-list" },
                [
                  _c(
                    "v-card-title",
                    [
                      _vm._v(" Players "),
                      !_vm.isDraft
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", title: "Export players" },
                              on: {
                                click: function ($event) {
                                  _vm.showTable = true
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-table")])],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.showPreviousSeasonStats
                    ? _c("v-card-subtitle", [_vm._v("Past season stats")])
                    : _vm._e(),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        [
                          _vm._l(_vm.positions, function (position, index) {
                            return _c(
                              "v-col",
                              {
                                key: index,
                                staticClass: "mx-sm-2",
                                attrs: { cols: "4", sm: "1" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "position-toggle",
                                    class: {
                                      "position-filter-active":
                                        _vm.filterActive(position.id),
                                    },
                                    attrs: {
                                      "data-position": position,
                                      title: position.display,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.togglePositionFilter(
                                          position.id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(position.short) + " ")]
                                ),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-col",
                            {
                              staticClass: "mx-sm-2",
                              attrs: { cols: "4", sm: "1" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "position-toggle",
                                  class: {
                                    "position-filter-active":
                                      _vm.freeAgentsOnly,
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.freeAgentsOnly = !_vm.freeAgentsOnly
                                    },
                                  },
                                },
                                [_vm._v(" FA ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("app-text-field", {
                        staticClass: "mx-4",
                        attrs: { label: "Search" },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.waiversActive
                    ? _c(
                        "v-card-subtitle",
                        [
                          _c(
                            "v-alert",
                            { attrs: { dense: "", color: "warning" } },
                            [
                              _vm._v(
                                "Waivers active until " + _vm._s(_vm.waiversEnd)
                              ),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://github.com/mdryden/110yards/wiki#waivers",
                                target: "_blank",
                              },
                            },
                            [_vm._v("How do waivers work?")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-text",
                    [
                      _vm.players
                        ? _c("v-data-table", {
                            attrs: {
                              "mobile-breakpoint": "0",
                              items: _vm.filteredPlayers,
                              headers: _vm.headers,
                              "sort-by": "points",
                              "sort-desc": true,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "top",
                                  fn: function () {
                                    return undefined
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "item.actions",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm.showActions
                                        ? _c(
                                            "div",
                                            [
                                              _vm.isAvailable(item) &&
                                              !_vm.isLocked(item)
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addPlayer(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-plus")]
                                                  )
                                                : _vm._e(),
                                              _vm.isAvailable(item) &&
                                              _vm.isLocked(item)
                                                ? _c("locked")
                                                : _vm._e(),
                                              _vm.isAuction &&
                                              _vm.isAvailable(item)
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        title:
                                                          "Nominate this player",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.nominateFunction(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-export-variant"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                                {
                                  key: "item.display_name",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("player-link", {
                                        attrs: {
                                          player: item,
                                          leagueId: _vm.leagueId,
                                          showStatus: true,
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.opponent",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.getNextOpponent(item))
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "item.points",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("score", {
                                        attrs: { score: item.points },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.average",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("score", {
                                        attrs: { score: item.average },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.last_week_score",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c("score", {
                                        attrs: { score: item.last_week_score },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item.position",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.position.toUpperCase()) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "item.owner",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _vm.isOwned(item)
                                        ? _c("league-roster-link", {
                                            attrs: {
                                              leagueId: _vm.leagueId,
                                              roster: _vm.getOwner(item.id),
                                              trim: true,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("data-table", {
            attrs: { data: _vm.players },
            on: {
              close: function ($event) {
                _vm.showTable = false
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }