var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAdmin && _vm.leagues
    ? _c(
        "div",
        [
          _vm.toDelete
            ? _c("v-alert", { attrs: { type: "warning" } }, [
                _vm._v("Delete this league?"),
              ])
            : _vm._e(),
          _c(
            "v-simple-table",
            [
              [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [_vm._v("ID")]),
                    _c("th", [_vm._v("Name")]),
                    _c("th", [_vm._v("Active")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.leagues, function (league) {
                    return _c(
                      "tr",
                      { key: league.id },
                      [
                        !_vm.toDelete || _vm.toDelete == league
                          ? [
                              _c(
                                "td",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        to: {
                                          name: "league",
                                          params: { leagueId: league.id },
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-link")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(league.id))]),
                              _c("td", [_vm._v(_vm._s(league.name))]),
                              _c(
                                "td",
                                [
                                  league.draft_state == "completed"
                                    ? _c(
                                        "v-chip",
                                        { attrs: { color: "green" } },
                                        [_c("v-icon", [_vm._v("mdi-check")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }