var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "6", cols: "12" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c("app-text-field", {
                attrs: {
                  label: "League name",
                  rules: _vm.leagueNameRules,
                  required: "",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              !_vm.leagueStarted
                ? _c(
                    "div",
                    [
                      _c("app-form-label", [
                        _vm._v("Make this league private?"),
                      ]),
                      _c(
                        "v-layout",
                        { staticClass: "mb-8", attrs: { "align-center": "" } },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.isPrivate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isPrivate", $$v)
                              },
                              expression: "form.isPrivate",
                            },
                          }),
                          _c("span", [_vm._v("Yes")]),
                        ],
                        1
                      ),
                      _c("app-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.isPrivate,
                            expression: "form.isPrivate",
                          },
                        ],
                        attrs: { label: "League password" },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                      _c("app-select", {
                        attrs: { label: "Draft type", items: _vm.draftTypes },
                        model: {
                          value: _vm.form.draft_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "draft_type", $$v)
                          },
                          expression: "form.draft_type",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _c(
                      "p",
                      [
                        _c("app-form-label", [
                          _vm._v(
                            "Private league: " +
                              _vm._s(_vm.form.isPrivate ? "Yes" : "No")
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.isPrivate,
                            expression: "form.isPrivate",
                          },
                        ],
                      },
                      [
                        _c("app-form-label", [
                          _vm._v(
                            "League password: " + _vm._s(_vm.form.password)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      [
                        _c("app-form-label", [
                          _vm._v("Draft type: " + _vm._s(_vm.draftTypeText)),
                        ]),
                      ],
                      1
                    ),
                  ]),
              _vm.enableDiscordIntegration
                ? _c(
                    "div",
                    [
                      _c(
                        "app-form-label",
                        [
                          _c("span", [_vm._v("Enable discord notifications?")]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                icon: "",
                                href: "https://github.com/mdryden/110yards/wiki#discord",
                                target: "_blank",
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-help-circle")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        { staticClass: "mb-2", attrs: { "align-center": "" } },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.enable_discord_notifications,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "enable_discord_notifications",
                                  $$v
                                )
                              },
                              expression: "form.enable_discord_notifications",
                            },
                          }),
                          _c("span", [_vm._v("Yes")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.enableDiscordIntegration &&
              _vm.form.enable_discord_notifications
                ? _c(
                    "div",
                    { staticClass: "ml-8" },
                    [
                      _c("app-text-field", {
                        attrs: {
                          label: "Discord webhook URL",
                          required: _vm.form.enable_discord_notifications,
                          rules: _vm.discordWebhookRules,
                        },
                        model: {
                          value: _vm.form.discord_webhook_url,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discord_webhook_url", $$v)
                          },
                          expression: "form.discord_webhook_url",
                        },
                      }),
                      _c("app-form-label", [
                        _c("span", [_vm._v("Send discord notifications for:")]),
                      ]),
                      _c(
                        "v-layout",
                        {
                          staticClass: "ml-2 mb-2 mt-2",
                          attrs: { "align-center": "" },
                        },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.notifications_draft,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "notifications_draft", $$v)
                              },
                              expression: "form.notifications_draft",
                            },
                          }),
                          _c("span", [_vm._v("Draft events")]),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "ml-2 mb-2",
                          attrs: { "align-center": "" },
                        },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.notifications_transactions,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "notifications_transactions",
                                  $$v
                                )
                              },
                              expression: "form.notifications_transactions",
                            },
                          }),
                          _c("span", [_vm._v("Transactions")]),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "ml-2 mb-2",
                          attrs: { "align-center": "" },
                        },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.notifications_waivers,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "notifications_waivers", $$v)
                              },
                              expression: "form.notifications_waivers",
                            },
                          }),
                          _c("span", [_vm._v("Waivers")]),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "ml-2 mb-2",
                          attrs: { "align-center": "" },
                        },
                        [
                          _c("v-simple-checkbox", {
                            staticClass: "form-check-input",
                            attrs: { ripple: false },
                            model: {
                              value: _vm.form.notifications_end_of_week,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "notifications_end_of_week",
                                  $$v
                                )
                              },
                              expression: "form.notifications_end_of_week",
                            },
                          }),
                          _c("span", [_vm._v("End of week")]),
                        ],
                        1
                      ),
                      _vm.form.discord_webhook_url
                        ? _c(
                            "app-default-button",
                            {
                              staticClass: "mb-4",
                              on: {
                                click: function ($event) {
                                  return _vm.testDiscordIntegration()
                                },
                              },
                            },
                            [_vm._v("Send a test notification")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("app-primary-button", [_vm._v("Update")]),
              _c("saved-indicator", { attrs: { saved: _vm.saved } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }