var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Hey, you shouldn't be here.")]),
      _c("h3", [
        _vm._v(
          "You are not authorized to do whatever it was you were trying to do."
        ),
      ]),
      _vm._m(0),
      _c("v-alert", { attrs: { type: "error" } }, [
        _vm._v("If this was not an error, stop doing that, ok?"),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" If you think this is an error, please "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/mdryden/110yards/issues",
            target: "_blank",
          },
        },
        [_vm._v("contact the admin")]
      ),
      _vm._v(", and let him know what happened. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }