var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "throbber" },
    [
      _c("loading", {
        attrs: {
          "is-full-page": true,
          active: _vm.active,
          color: "red",
          "background-color": "black",
          loader: "spinner",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }