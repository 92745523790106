var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: { "full-width": false, outlined: "", dense: "", items: _vm.items },
    on: { change: _vm.viewSeason },
    model: {
      value: _vm.selectedSeason,
      callback: function ($$v) {
        _vm.selectedSeason = $$v
      },
      expression: "selectedSeason",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }