var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.draft && _vm.rosters
    ? _c(
        "div",
        { staticClass: "draft" },
        [
          _vm.isCommissioner
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("commissioner-draft-tools", {
                        attrs: {
                          draft: _vm.draft,
                          leagueId: _vm.leagueId,
                          previousSlot: _vm.previousSlot,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.draft.is_paused
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      !_vm.isCommissioner
                        ? _c("v-alert", { attrs: { type: "info" } }, [
                            _vm._v("The commissioner has paused the draft"),
                          ])
                        : _vm._e(),
                      _c("draft-events", {
                        attrs: { events: _vm.draft.draft_events },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("auction-slot", {
                    attrs: {
                      draftType: _vm.draft.draft_type,
                      draftSlot: _vm.currentSlot,
                      rosters: _vm.rosters,
                      leagueId: _vm.leagueId,
                      isPaused: _vm.draft.is_paused,
                      isCommissioner: _vm.isCommissioner,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [_c("last-draft-pick-card", { attrs: { draft: _vm.draft } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "9" } },
                [
                  _c("player-list", {
                    attrs: {
                      leagueId: _vm.leagueId,
                      showActions: _vm.showPlayerActions,
                      isAuction: true,
                      nominateFunction: _vm.nominatePlayer,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("draft-rosters", {
                    attrs: {
                      isAuction: true,
                      leagueId: _vm.leagueId,
                      rosters: _vm.rosters,
                    },
                    on: { rosterChange: _vm.updateSelectedRoster },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.draft.is_paused
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("draft-events", {
                        attrs: { events: _vm.draft.draft_events },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }