var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Receiving")]),
      _vm._l(_vm.inScope, function (player) {
        return _c("EditPlayerStats", {
          key: player.player_id,
          attrs: {
            season: _vm.season,
            text: _vm.getText(player),
            playerGame: player,
          },
          on: {
            save: function ($event) {
              return _vm.$emit("save")
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }