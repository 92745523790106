import { render, staticRenderFns } from "./ArchiveLeagues.vue?vue&type=template&id=41943e58&"
import script from "./ArchiveLeagues.vue?vue&type=script&lang=js&"
export * from "./ArchiveLeagues.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fantasy/fantasy/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41943e58')) {
      api.createRecord('41943e58', component.options)
    } else {
      api.reload('41943e58', component.options)
    }
    module.hot.accept("./ArchiveLeagues.vue?vue&type=template&id=41943e58&", function () {
      api.rerender('41943e58', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/league/ArchiveLeagues.vue"
export default component.exports