var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "400px" } },
    [
      _c("v-card-title", [_vm._v("Complete sign in")]),
      _c("v-card-text", [
        _c(
          "p",
          [
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c("app-text-field", {
                  attrs: {
                    label: "Email",
                    rules: _vm.emailRules,
                    autocomplete: "email",
                    required: "",
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
                _c("app-primary-button", { attrs: { block: "" } }, [
                  _vm._v("Log in"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("p", { staticClass: "text-center" }, [_c("why-passwordless")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }