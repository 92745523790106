var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.wasAdjusted
        ? _c(
            "v-alert",
            { staticClass: "mb-10", attrs: { type: "info", dense: "" } },
            [
              _c("span", [
                _vm._v(
                  "Scores for this matchup were adjusted by the commissioner."
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.league && _vm.matchup
        ? _c(
            "v-sheet",
            [
              _c("matchup-header", {
                attrs: {
                  matchup: _vm.matchup,
                  away: _vm.awayRoster,
                  home: _vm.homeRoster,
                  weekNumber: _vm.weekNumber,
                  enableProjections: _vm.enableProjections,
                  isCurrentWeek: _vm.isCurrentWeek,
                },
              }),
              !_vm.editing
                ? _c("matchup-vs", {
                    staticClass: "d-none d-md-block",
                    attrs: {
                      league: _vm.league,
                      away: _vm.awayRoster,
                      home: _vm.homeRoster,
                      enableProjections: _vm.enableProjections,
                      weekNumber: _vm.weekNumber,
                    },
                  })
                : _vm._e(),
              !_vm.editing
                ? _c("matchup-vs", {
                    staticClass: "d-md-none",
                    attrs: {
                      mobile: "",
                      league: _vm.league,
                      away: _vm.awayRoster,
                      home: _vm.homeRoster,
                      enableProjections: _vm.enableProjections,
                      weekNumber: _vm.weekNumber,
                    },
                  })
                : _vm._e(),
              _vm.canEdit && !_vm.editing
                ? _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-btn", { on: { click: _vm.startEditing } }, [
                            _vm._v("Edit scores"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.editing
        ? _c(
            "v-sheet",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c("p", { staticClass: "subtitle-2" }, [
                        _vm._v(
                          " As the commissioner, you may apply an adjustment to either or both teams in order to correct an error. Results will be re-calculated when you save. Adjustments may be positive or negative. "
                        ),
                      ]),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.applyAdjustments()
                            },
                          },
                        },
                        [
                          _c("app-number-field", {
                            attrs: {
                              label: _vm.awayRoster.name + " adjustment",
                              required: "",
                            },
                            model: {
                              value: _vm.form.away_adjustment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "away_adjustment", $$v)
                              },
                              expression: "form.away_adjustment",
                            },
                          }),
                          _c("app-number-field", {
                            attrs: {
                              label: _vm.homeRoster.name + " adjustment",
                              required: "",
                            },
                            model: {
                              value: _vm.form.home_adjustment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "home_adjustment", $$v)
                              },
                              expression: "form.home_adjustment",
                            },
                          }),
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v("Adjusted scores"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "body-2" },
                            [
                              _vm._v(_vm._s(_vm.awayRoster.name) + ": "),
                              _c("Score", {
                                attrs: { score: _vm.adjustedScores.away },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "body-2" },
                            [
                              _vm._v(_vm._s(_vm.homeRoster.name) + ": "),
                              _c("Score", {
                                attrs: { score: _vm.adjustedScores.home },
                              }),
                            ],
                            1
                          ),
                          _c("app-primary-button", [_vm._v("Update")]),
                          _c(
                            "app-default-button",
                            {
                              staticClass: "ml-2",
                              on: {
                                click: function ($event) {
                                  _vm.editing = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }