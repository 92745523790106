var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      attrs: {
        to: _vm.to,
        color: "primary_button",
        type: _vm.type,
        block: _vm.block,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }