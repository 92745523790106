var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "standings" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-col", {
                    staticClass: "caption",
                    attrs: { cols: "6", md: "6" },
                  }),
                  _c(
                    "v-col",
                    { staticClass: "caption", attrs: { cols: "2", md: "1" } },
                    [_vm._v("Pts")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "caption text-center",
                      attrs: { cols: "2", md: "1" },
                    },
                    [_vm._v("LW")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "caption text-center",
                      attrs: { cols: "2" },
                    },
                    [_vm._v("$")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.rosters, function (roster) {
                return _c(
                  "v-row",
                  { key: roster.id, staticClass: "mt-0" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "roster-name",
                        attrs: { cols: "6", md: "6" },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "roster",
                                params: {
                                  leagueId: _vm.leagueId,
                                  rosterId: roster.id,
                                },
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(roster.name))]),
                            _vm.isCommissionersRoster(roster)
                              ? _c(
                                  "v-icon",
                                  { staticClass: "ml-2 commissioner" },
                                  [_vm._v("mdi-star")]
                                )
                              : _vm._e(),
                            _vm.isCommissionersRoster(roster)
                              ? _c("span", { staticClass: "d-sr-only" }, [
                                  _vm._v("Commissioner"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "caption" }, [
                          _vm._v(" " + _vm._s(roster.record) + " "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "px-0", attrs: { cols: "2", md: "1" } },
                      [_vm._v(_vm._s(_vm.formatScore(roster.points_for)))]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "px-0 text-center",
                        attrs: { cols: "2", md: "1" },
                      },
                      [_vm._v(_vm._s(roster.last_week_result))]
                    ),
                    _c(
                      "v-col",
                      { staticClass: "px-0 text-center", attrs: { cols: "2" } },
                      [_vm._v("$" + _vm._s(roster.waiver_budget))]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }