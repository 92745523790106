var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Moderator Tools")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("transactions")
                },
              },
            },
            [_vm._v("Transactions")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("corrections")
                },
              },
            },
            [_vm._v("Corrections")]
          ),
          _vm.enablePlayerApprovals
            ? _c(
                "v-btn",
                {
                  staticClass: "caption",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.setView("players")
                    },
                  },
                },
                [
                  _vm._v("Players "),
                  _vm.highlightPlayers
                    ? _c("v-badge", {
                        staticClass: "ml-2",
                        attrs: { dot: "", color: "green" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.view == "transactions" ? _c("Transactions") : _vm._e(),
          _vm.view == "corrections" ? _c("Corrections") : _vm._e(),
          _vm.view == "players" ? _c("players") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }