var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.saved
      ? _c("span", { staticClass: "saved" }, [
          _vm._v("Saved "),
          _c("i", { staticClass: "fas fa-check" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }