var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-select", {
        attrs: { items: _vm.rosterSelections, label: "Rosters" },
        model: {
          value: _vm.selectedRosterId,
          callback: function ($$v) {
            _vm.selectedRosterId = $$v
          },
          expression: "selectedRosterId",
        },
      }),
      _vm._l(_vm.rosters, function (roster) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: roster.id == _vm.selectedRosterId,
                expression: "roster.id == selectedRosterId",
              },
            ],
            key: roster.id,
            staticClass: "roster",
          },
          [
            _c("h4", [
              _vm.isAuction
                ? _c("span", [_vm._v("$" + _vm._s(roster.draft_budget))])
                : _vm._e(),
            ]),
            _c("lineup", { attrs: { leagueId: _vm.leagueId, roster: roster } }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }