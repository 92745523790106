var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { md: "6", cols: "12" } },
        [
          _c(
            "v-chip",
            { staticClass: "ma-2", attrs: { color: _vm.apiStatusColor } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-wrench")]),
              _c("span", [
                _vm._v("API status " + _vm._s(_vm.apiStatus.status)),
              ]),
            ],
            1
          ),
          _c(
            "v-chip",
            { staticClass: "ma-2", attrs: { color: "blue", outlined: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-account")]),
              _c("span", [
                _vm._v("Users: " + _vm._s(_vm.stats.verified_user_count)),
              ]),
            ],
            1
          ),
          _c(
            "v-chip",
            { staticClass: "ma-2", attrs: { color: "green", outlined: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-fire")]),
              _c("span", [
                _vm._v(
                  "Active Leagues " + _vm._s(_vm.stats.active_league_count)
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-chip",
            { staticClass: "ma-2", attrs: { color: "red", outlined: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-fire")]),
              _c("span", [
                _vm._v("Total Leagues " + _vm._s(_vm.stats.league_count)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }