var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.roster
    ? _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _vm._v(" " + _vm._s(_vm.roster.name) + " "),
              _vm.wasChampion
                ? _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { small: "", color: "grey" },
                    },
                    [_vm._v("mdi-trophy")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-card-subtitle", [
            _vm._v(
              _vm._s(_vm.roster.record) + " (" + _vm._s(_vm.formattedRank) + ")"
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                _vm._l(_vm.sortedSpots, function (position) {
                  return _c(
                    "v-row",
                    { key: position.position_id },
                    [
                      _c("v-col", { attrs: { cols: "2", md: "1" } }, [
                        _vm._v(" " + _vm._s(position.name)),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "league-player",
                                  params: {
                                    leagueId: _vm.leagueId,
                                    playerId: position.player_id,
                                  },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(position.player_name) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }