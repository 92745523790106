var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      [
        _c("AppDefaultButton", { on: { click: _vm.ownershipReport } }, [
          _vm._v("Ownership report"),
        ]),
      ],
      1
    ),
    _vm.reportReady
      ? _c("div", [
          _c(
            "h2",
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.copyData } },
                [_c("v-icon", [_vm._v("mdi-content-copy")])],
                1
              ),
            ],
            1
          ),
          _c("pre", { attrs: { id: "reportData" } }, [
            _vm._v(_vm._s(_vm.reportData)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }