var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-icon", { attrs: { color: "grey" } }, [
    _vm._v("mdi-lock-outline"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }