var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ReviewPassing", {
        attrs: { season: _vm.season, players: _vm.players },
      }),
      _c("ReviewRushing", {
        staticClass: "mt-6",
        attrs: { season: _vm.season, players: _vm.players },
      }),
      _c("ReviewReceiving", {
        staticClass: "mt-6",
        attrs: { season: _vm.season, players: _vm.players },
      }),
      _c("ReviewDefense", {
        staticClass: "mt-6",
        attrs: { season: _vm.season, players: _vm.players },
      }),
      _c("ReviewKicking", {
        staticClass: "mt-6",
        attrs: { season: _vm.season, players: _vm.players },
      }),
      _c("ReviewPunting", {
        staticClass: "mt-6",
        attrs: { season: _vm.season, players: _vm.players },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }