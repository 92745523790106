var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.draft
    ? _c(
        "v-card",
        { staticClass: "snake-draft-picks" },
        [
          _c("v-card-title", [_vm._v("Draft")]),
          _c(
            "v-card-text",
            _vm._l(_vm.draft.slots, function (slot) {
              return _c("p", { key: slot.pick_number }, [
                _vm._v(
                  " Pick " +
                    _vm._s(slot.pick_number) +
                    " - " +
                    _vm._s(_vm.getRosterName(slot.roster_id)) +
                    " "
                ),
                slot.completed
                  ? _c("span", [_vm._v(" - " + _vm._s(slot.player.id) + " ")])
                  : _vm._e(),
              ])
            }),
            0
          ),
          _vm.yourTurn ? _c("v-card-text", [_vm._v(" Your turn! ")]) : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }