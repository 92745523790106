var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Oh no.")]),
      _c("h2", [_vm._v("Something went wrong. Sorry about that.")]),
      _vm._m(0),
      _c("v-alert", { attrs: { type: "error" } }, [
        _vm._v(" " + _vm._s(_vm.message) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" The error has been logged, but you may also want to "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/mdryden/110yards/issues",
            target: "_blank",
          },
        },
        [_vm._v("contact the admin")]
      ),
      _vm._v(", and let him know what happened. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }