var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "draft-events" },
    [
      _c("v-card-title", [_vm._v("Draft Events")]),
      _c(
        "v-card-text",
        _vm._l(_vm.events, function (event, index) {
          return _c("p", { key: index }, [_vm._v(" " + _vm._s(event) + " ")])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }