var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enableDraft
    ? _c(
        "div",
        { staticClass: "draft-button my-2" },
        [
          _vm.canStartDraft
            ? _c(
                "app-primary-button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.startDraft },
                },
                [_vm._v(" Start Draft ")]
              )
            : _vm._e(),
          _vm.canResumeDraft
            ? _c(
                "app-primary-button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.goToDraft },
                },
                [_vm._v(" Resume Draft ")]
              )
            : _vm._e(),
          _vm.canJoinDraft
            ? _c(
                "app-primary-button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.goToDraft },
                },
                [_vm._v(" Join Draft ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }