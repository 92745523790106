var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.details
        ? _c(
            "div",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [
                            _c(
                              "div",
                              {
                                staticClass: "team-colors",
                                class: _vm.details.player.team.abbr,
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.details.player.uniform || "-") +
                                    " "
                                ),
                              ]
                            ),
                            _vm._v(
                              " " +
                                _vm._s(_vm.details.player.display_name) +
                                " "
                            ),
                          ]),
                          _c("v-card-subtitle", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.details.player.position.toUpperCase()
                                )
                              ),
                            ]),
                            _c("span", [_vm._v(" - ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.details.player.team.location) +
                                  " " +
                                  _vm._s(_vm.details.player.team.name)
                              ),
                            ]),
                            _vm.statusText
                              ? _c("div", { class: _vm.statusClass }, [
                                  _vm._v(_vm._s(_vm.statusText)),
                                ])
                              : _vm._e(),
                          ]),
                          _c("v-card-subtitle", [
                            _vm.details.season_score &&
                            _vm.details.season_score.rank
                              ? _c("div", [
                                  _c("label", [_vm._v("Fantasy Rank:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.details.season_score.rank) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("v-card-subtitle", [
                            _vm.details.player.age
                              ? _c("div", [
                                  _c("label", [_vm._v("Age:")]),
                                  _vm._v(" " + _vm._s(_vm.details.player.age)),
                                ])
                              : _vm._e(),
                            _vm.hasHeight && _vm.hasWeight
                              ? _c("div", [
                                  _c("label", [_vm._v("Height/Weight:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.details.player.formatted_height
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.details.player.formatted_weight
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.hasHeight && !_vm.hasWeight
                              ? _c("div", [
                                  _c("label", [_vm._v("Height:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.details.player.formatted_height
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.hasHeight && _vm.hasWeight
                              ? _c("div", [
                                  _c("label", [_vm._v("Weight:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.details.player.formatted_weight
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            _vm.details.player.birth_place
                              ? _c("div", [
                                  _c("label", [_vm._v("Hometown:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.details.player.birth_place)
                                  ),
                                ])
                              : _vm._e(),
                            _vm.details.player.college
                              ? _c("div", [
                                  _c("label", [_vm._v("College:")]),
                                  _vm._v(
                                    " " + _vm._s(_vm.details.player.college)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.details
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("h4", [_vm._v("Game Log")]),
                          _c("v-alert", { attrs: { type: "warning" } }, [
                            _vm._v(
                              "There is currently a data issue affecting this page causing all scores to be 0-0. This will be fixed as soon as possible."
                            ),
                          ]),
                          _vm.details.game_log
                            ? _c(
                                "v-simple-table",
                                [
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { attrs: { colspan: "2" } }),
                                        _vm.showPassing
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "6" } },
                                              [_vm._v("Passing")]
                                            )
                                          : _vm._e(),
                                        _vm.showRushing
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "5" } },
                                              [_vm._v("Rushing")]
                                            )
                                          : _vm._e(),
                                        _vm.showReceiving
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "6" } },
                                              [_vm._v("Receiving")]
                                            )
                                          : _vm._e(),
                                        _vm.showFumbles ? _c("th") : _vm._e(),
                                        _vm.showConvert2 ? _c("th") : _vm._e(),
                                        _vm.showDefence
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "7" } },
                                              [_vm._v("Defense")]
                                            )
                                          : _vm._e(),
                                        _vm.showReturning
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "7" } },
                                              [_vm._v("Kick Returns")]
                                            )
                                          : _vm._e(),
                                        _vm.showKicking
                                          ? _c(
                                              "th",
                                              { attrs: { colspan: "7" } },
                                              [_vm._v("Kicking")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "tr",
                                        [
                                          _c(
                                            "th",
                                            { attrs: { colspan: "1" } },
                                            [_vm._v("Game")]
                                          ),
                                          _c("th", [_vm._v("Score")]),
                                          _vm.showPassing
                                            ? [
                                                _c("th", [_vm._v("Passes")]),
                                                _c("th", [_vm._v("Comp")]),
                                                _c("th", [_vm._v("Yards")]),
                                                _c("th", [_vm._v("Y/A")]),
                                                _c("th", [_vm._v("TD")]),
                                                _c("th", [_vm._v("Int")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showRushing &&
                                          _vm.showRushingFirst
                                            ? [
                                                _c("th", [_vm._v("Rushes")]),
                                                _c("th", [_vm._v("Yards")]),
                                                _c("th", [_vm._v("TD")]),
                                                _c("th", [_vm._v("Avg")]),
                                                _c("th", [_vm._v("Long")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showReceiving
                                            ? [
                                                _c("th", [_vm._v("Rec")]),
                                                _c("th", [_vm._v("Att")]),
                                                _c("th", [_vm._v("Yards")]),
                                                _c("th", [_vm._v("TD")]),
                                                _c("th", [_vm._v("Avg")]),
                                                _c("th", [_vm._v("Long")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showRushing &&
                                          !_vm.showRushingFirst
                                            ? [
                                                _c("th", [_vm._v("Rushes")]),
                                                _c("th", [_vm._v("Yards")]),
                                                _c("th", [_vm._v("TD")]),
                                                _c("th", [_vm._v("Avg")]),
                                                _c("th", [_vm._v("Long")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showFumbles
                                            ? [_c("th", [_vm._v("Fum")])]
                                            : _vm._e(),
                                          _vm.showConvert2
                                            ? [_c("th", [_vm._v("2PT")])]
                                            : _vm._e(),
                                          _vm.showDefence
                                            ? [
                                                _c("th", [_vm._v("TT")]),
                                                _c("th", [_vm._v("DT")]),
                                                _c("th", [_vm._v("ST")]),
                                                _c("th", [_vm._v("QS")]),
                                                _c("th", [_vm._v("INT")]),
                                                _c("th", [_vm._v("FF")]),
                                                _c("th", [_vm._v("FR")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showReturning
                                            ? [
                                                _c("th", [_vm._v("FG Yd")]),
                                                _c("th", [_vm._v("FG TD")]),
                                                _c("th", [_vm._v("KR Yd")]),
                                                _c("th", [_vm._v("KR TD")]),
                                                _c("th", [_vm._v("PR Yd")]),
                                                _c("th", [_vm._v("PR TD")]),
                                              ]
                                            : _vm._e(),
                                          _vm.showKicking
                                            ? [
                                                _c("th", [_vm._v("FGA")]),
                                                _c("th", [_vm._v("Make")]),
                                                _c("th", [_vm._v("Miss")]),
                                                _c("th", [_vm._v("%")]),
                                                _c("th", [_vm._v("1PT")]),
                                                _c("th", [_vm._v("Sgl")]),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.details.game_log,
                                          function (log) {
                                            return [
                                              _c(
                                                "tr",
                                                { key: "stats" + log.game_id },
                                                [
                                                  log.game
                                                    ? _c("game-result", {
                                                        attrs: {
                                                          game: log.game,
                                                          playerTeam: log.team,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-no-wrap",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatScore(
                                                              log.score
                                                                ? log.score
                                                                    .total_score
                                                                : 0
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      !_vm.detailedLog
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mt-n1 pl-1",
                                                              attrs: {
                                                                small: "",
                                                                color: "grey",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.detailedLog =
                                                                      log
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-help-circle"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.detailedLog &&
                                                      _vm.detailedLog.game_id ==
                                                        log.game_id
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mt-n1 pl-1",
                                                              attrs: {
                                                                small: "",
                                                                color: "grey",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.detailedLog =
                                                                      null
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.showPassing
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .pass_attempts,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .pass_completions,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .pass_net_yards,
                                                          },
                                                        }),
                                                        _c("game-average", {
                                                          attrs: {
                                                            attempts:
                                                              log.stats
                                                                .pass_completions,
                                                            yards:
                                                              log.stats
                                                                .pass_net_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .pass_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .pass_interceptions,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showRushing &&
                                                  _vm.showRushingFirst
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_attempts,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_net_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-average", {
                                                          attrs: {
                                                            attempts:
                                                              log.stats
                                                                .rush_attempts,
                                                            yards:
                                                              log.stats
                                                                .rush_net_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_long,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showReceiving
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .receive_caught,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .receive_attempts,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .receive_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .receive_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-average", {
                                                          attrs: {
                                                            attempts:
                                                              log.stats
                                                                .receive_attempts,
                                                            yards:
                                                              log.stats
                                                                .receive_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .receive_long,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showFumbles
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              _vm.getFumbleCount(
                                                                log.stats
                                                              ),
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showConvert2
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .two_point_converts_made,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showRushing &&
                                                  !_vm.showRushingFirst
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_attempts,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_net_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-average", {
                                                          attrs: {
                                                            attempts:
                                                              log.stats
                                                                .rush_attempts,
                                                            yards:
                                                              log.stats
                                                                .rush_net_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .rush_long,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showDefence
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .tackles_total,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .tackles_defensive,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .tackles_special_teams,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .sacks_qb_made,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .interceptions,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .fumbles_forced,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .fumbles_recovered,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showReturning
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .field_goal_returns_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .field_goal_returns_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .kick_returns_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .kick_returns_touchdowns,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .punt_returns_yards,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .punt_returns_touchdowns,
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.showKicking
                                                    ? [
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .field_goal_attempts,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .field_goal_made,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .field_goal_misses,
                                                          },
                                                        }),
                                                        _c("game-percent", {
                                                          attrs: {
                                                            attempts:
                                                              log.stats
                                                                .field_goal_attempts,
                                                            successes:
                                                              log.stats
                                                                .field_goal_made,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              log.stats
                                                                .one_point_converts_made,
                                                          },
                                                        }),
                                                        _c("game-value", {
                                                          attrs: {
                                                            value:
                                                              _vm.getSingleCount(
                                                                log.stats
                                                              ),
                                                          },
                                                        }),
                                                      ]
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _vm.detailedLog &&
                                              _vm.detailedLog.game_id ==
                                                log.game_id
                                                ? _c(
                                                    "tr",
                                                    {
                                                      key:
                                                        "scoring" + log.game_id,
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          "Score details:"
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.formatScore(
                                                                log.score
                                                                  ? log.score
                                                                      .total_score
                                                                  : 0
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _vm.showPassing
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .pass_attempts,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .pass_completions,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .pass_net_yards,
                                                              },
                                                            }),
                                                            _c("td"),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .pass_touchdowns,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .pass_interceptions,
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showRushing &&
                                                      _vm.showRushingFirst
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_attempts,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_net_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_touchdowns,
                                                              },
                                                            }),
                                                            _c("game-average", {
                                                              attrs: {
                                                                attempts:
                                                                  log.score
                                                                    .rush_attempts,
                                                                yards:
                                                                  log.score
                                                                    .rush_net_yards,
                                                              },
                                                            }),
                                                            _c("td"),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showReceiving
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .receive_caught,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .receive_attempts,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .receive_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .receive_touchdowns,
                                                              },
                                                            }),
                                                            _c("td"),
                                                            _c("td"),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showFumbles
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  _vm.getFumbleScore(
                                                                    log.score
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showConvert2
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .two_point_converts_made,
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showRushing &&
                                                      !_vm.showRushingFirst
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_attempts,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_net_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .rush_touchdowns,
                                                              },
                                                            }),
                                                            _c("game-average", {
                                                              attrs: {
                                                                attempts:
                                                                  log.score
                                                                    .rush_attempts,
                                                                yards:
                                                                  log.score
                                                                    .rush_net_yards,
                                                              },
                                                            }),
                                                            _c("td"),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showDefence
                                                        ? [
                                                            _c("td"),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .tackles_defensive,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .tackles_special_teams,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .sacks_qb_made,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .interceptions,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .fumbles_forced,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .fumbles_recovered,
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showReturning
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .field_goal_returns_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .field_goal_returns_touchdowns,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .kick_returns_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .kick_returns_touchdowns,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .punt_returns_yards,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .punt_returns_touchdowns,
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                      _vm.showKicking
                                                        ? [
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .field_goal_attempts,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .field_goal_made,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .field_goal_misses,
                                                              },
                                                            }),
                                                            _c("game-percent", {
                                                              attrs: {
                                                                attempts:
                                                                  log.score
                                                                    .field_goal_attempts,
                                                                successes:
                                                                  log.score
                                                                    .field_goal_made,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  log.score
                                                                    .one_point_converts_made,
                                                              },
                                                            }),
                                                            _c("game-value", {
                                                              attrs: {
                                                                value:
                                                                  _vm.getSingleScore(
                                                                    log.score
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _c("v-card-text", [_vm._v("No games")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.notFound
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("Player not found"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }