var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "brand" }, [_vm._v("110 yards")]),
    _vm.enablePublicLeagues
      ? _c("h5", [
          _vm._v("Find a public league, or enter a private league code."),
        ])
      : _c("h5", [
          _vm._v("Enter private league code and password if required"),
        ]),
    _c("section", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.enablePublicLeagues
          ? _c("h5", [_vm._v("Join a private league")])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("join-private-form")], 1),
        ]),
      ]),
    ]),
    _c("hr"),
    _vm.enablePublicLeagues
      ? _c("section", { staticClass: "row" }, [_vm._m(0)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h4", [_vm._v("Join a public league")]),
      _c("table", { staticClass: "table" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("League Name")]),
            _c("th", [_vm._v("Teams")]),
            _c("th", [_vm._v("Draft Date")]),
            _c("th", [_vm._v("Scoring")]),
            _c("th"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }