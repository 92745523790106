var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Transactions")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-simple-table",
            [
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v("Timestamp"),
                    ]),
                    _c("th", { staticClass: "text-left" }, [_vm._v("Type")]),
                    _c("th", { staticClass: "text-left" }, [_vm._v("Message")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.transactions, function (transaction) {
                    return _c("tr", { key: transaction.id }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.formatDate(transaction.timestamp.toDate()))
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(transaction.transaction_type))]),
                      _c("td", [_vm._v(_vm._s(transaction.message))]),
                    ])
                  }),
                  0
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }