var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.player
    ? _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("div", [
            _vm._v(" " + _vm._s(_vm.player.display_name) + " "),
            _vm.playerGame.manual_override && !_vm.resetting
              ? _c(
                  "a",
                  {
                    staticClass: "pl-1",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.confirmRevert()
                      },
                    },
                  },
                  [_vm._v("Revert")]
                )
              : _vm._e(),
          ]),
          _c("span", { class: _vm.textClass }, [_vm._v(_vm._s(_vm.text))]),
          !_vm.editing
            ? _c(
                "a",
                {
                  staticClass: "pl-1",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.startEditing()
                    },
                  },
                },
                [_vm._v("Edit")]
              )
            : _vm._e(),
          _vm.editing
            ? _c(
                "v-container",
                [
                  _c("v-row", [_c("h4", [_vm._v("Passing")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.pass_completions,
                              label: "COMP",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.pass_completions =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.pass_attempts,
                              label: "ATT",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.pass_attempts = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.pass_net_yards,
                              label: "YDS",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.pass_net_yards = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.pass_touchdowns,
                              label: "TD",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.pass_touchdowns = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.pass_interceptions,
                              label: "INT",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.pass_interceptions =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("h4", [_vm._v("Rushing")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.rush_attempts,
                              label: "ATT",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.rush_attempts = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.rush_net_yards,
                              label: "YDS",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.rush_net_yards = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.rush_touchdowns,
                              label: "TD",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.rush_touchdowns = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("h4", [_vm._v("Receiving")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.receive_caught,
                              label: "REC",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.receptions = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.receive_yards,
                              label: "YDS",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.receive_yards = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.receive_touchdowns,
                              label: "TD",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.receive_touchdowns =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("h4", [_vm._v("Defense")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.tackles_defensive,
                              label: "TKL",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.tackles_defensive =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.tackles_special_teams,
                              label: "STT",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.tackles_special_teams =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.sacks_qb_made,
                              label: "SACK",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.sacks_qb_made = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.interceptions,
                              label: "INT",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.interceptions = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.fumbles_recovered,
                              label: "FR",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.fumbles_recovered =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("h4", [_vm._v("Kicking")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.field_goal_made,
                              label: "FGM",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.field_goal_made = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.field_goal_attempts,
                              label: "FGA",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.field_goal_attempts =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value:
                                "" + _vm.editedStats.one_point_converts_made,
                              label: "XP",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.one_point_converts_made =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value:
                                "" +
                                _vm.editedStats.one_point_converts_attempts,
                              label: "XPA",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.one_point_converts_attempts =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.field_goal_singles,
                              label: "Singles",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.field_goal_singles =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("h4", [_vm._v("Punting")])]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.punts,
                              label: "Punts",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.punts = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.punt_gross_yards,
                              label: "Yards",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.punt_gross_yards =
                                  value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4", md: "2" } },
                        [
                          _c("AppTextField", {
                            attrs: {
                              value: "" + _vm.editedStats.punt_singles,
                              label: "Singles",
                            },
                            on: {
                              input: function (value) {
                                return (_vm.editedStats.punt_singles = value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("AppPrimaryButton", { on: { click: _vm.save } }, [
                    _vm._v("Save"),
                  ]),
                  _c(
                    "AppDefaultButton",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.reverting
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v("Undo overrides and revert to imported game stats?"),
                  ]),
                  _c("AppPrimaryButton", { on: { click: _vm.revert } }, [
                    _vm._v("Yes"),
                  ]),
                  _c(
                    "AppDefaultButton",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("No")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }