var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c(
        "a",
        { attrs: { href: "https://www.buymeacoffee.com/mdryden" } },
        [
          _c(
            "v-alert",
            {
              class: { "mt-2": !_vm.top },
              attrs: { dense: "", icon: "mdi-gift", type: "success" },
            },
            [_vm._v(" " + _vm._s(_vm.message) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }