var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league
    ? _c(
        "div",
        [
          _c("league-header", {
            staticClass: "mb-n16",
            attrs: { leagueName: _vm.league.name, leagueId: _vm.league.id },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8" } },
                [
                  _c("start-draft", { attrs: { league: _vm.league } }),
                  _vm.canRenewLeague
                    ? _c(
                        "app-primary-button",
                        { on: { click: _vm.renewLeague } },
                        [
                          _vm._v(
                            "Start " + _vm._s(_vm.currentSeason) + " Season"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isOffseason
                    ? _c("season-summary", {
                        attrs: {
                          leagueId: _vm.leagueId,
                          season: _vm.league.season,
                        },
                      })
                    : _vm._e(),
                  !_vm.isOffseason
                    ? _c(
                        "v-toolbar",
                        [
                          _c(
                            "v-tabs",
                            {
                              model: {
                                value: _vm.tabA,
                                callback: function ($$v) {
                                  _vm.tabA = $$v
                                },
                                expression: "tabA",
                              },
                            },
                            [
                              _vm.scheduleGenerated
                                ? _c("v-tab", { key: "schedule" }, [
                                    _vm._v("Schedule"),
                                  ])
                                : _vm._e(),
                              _c("v-tab", { key: "standings" }, [
                                _vm._v("Standings"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tabA,
                        callback: function ($$v) {
                          _vm.tabA = $$v
                        },
                        expression: "tabA",
                      },
                    },
                    [
                      _vm.scheduleGenerated
                        ? _c(
                            "v-tab-item",
                            { key: "schedule" },
                            [
                              _c("schedule", {
                                attrs: { leagueId: _vm.leagueId },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab-item",
                        { key: "standings" },
                        [
                          !_vm.isOffseason
                            ? _c("standings", {
                                staticClass: "mt-5",
                                attrs: { league: _vm.league },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-toolbar",
                    [
                      _c(
                        "v-tabs",
                        {
                          model: {
                            value: _vm.tabB,
                            callback: function ($$v) {
                              _vm.tabB = $$v
                            },
                            expression: "tabB",
                          },
                        },
                        [
                          _c("v-tab", { key: "transactions" }, [
                            _vm._v("League Transactions"),
                          ]),
                          _c("v-tab", { key: "news" }, [_vm._v("CFL News")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tabB,
                        callback: function ($$v) {
                          _vm.tabB = $$v
                        },
                        expression: "tabB",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "transactions" },
                        [
                          !_vm.isOffseason
                            ? _c("transactions", {
                                staticClass: "mt-5",
                                attrs: { leagueId: _vm.leagueId },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-tab-item", { key: "news" }, [_c("news")], 1),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-none d-md-flex", attrs: { cols: "4" } },
                [_c("scoreboard")],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }