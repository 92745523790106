var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: _vm.timeout, color: _vm.color },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function (ref) {
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.visible = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [_vm._v(" " + _vm._s(_vm.message) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }