var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.enoughTeams
        ? _c(
            "v-row",
            [
              _c("v-alert", { attrs: { type: "warning" } }, [
                _vm._v(" League must have an even number of teams. "),
              ]),
            ],
            1
          )
        : _c(
            "v-row",
            [
              !_vm.league.registration_closed
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-alert", { attrs: { type: "warning" } }, [
                        _vm._v(
                          "Generating the schedule will lock registration"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        },
                      },
                    },
                    [
                      !_vm.leagueStarted
                        ? _c("app-select", {
                            attrs: {
                              items: _vm.eligiblePlayoffTypes,
                              label: "Playoff type",
                              rules: _vm.playoffTypeRules,
                              required: "",
                            },
                            model: {
                              value: _vm.form.playoffType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "playoffType", $$v)
                              },
                              expression: "form.playoffType",
                            },
                          })
                        : _c(
                            "p",
                            [
                              _c("app-form-label", [
                                _vm._v(
                                  "Playoff teams: " +
                                    _vm._s(_vm.form.playoffType)
                                ),
                              ]),
                            ],
                            1
                          ),
                      !_vm.leagueStarted
                        ? _c("app-select", {
                            attrs: {
                              label: "First playoff week",
                              items: _vm.weekCounts,
                              rules: _vm.firstPlayoffWeekRules,
                              required: "",
                            },
                            model: {
                              value: _vm.form.firstPlayoffWeek,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "firstPlayoffWeek", $$v)
                              },
                              expression: "form.firstPlayoffWeek",
                            },
                          })
                        : _c(
                            "p",
                            [
                              _c("app-form-label", [
                                _vm._v(
                                  "First playoff week: " +
                                    _vm._s(_vm.form.firstPlayoffWeek)
                                ),
                              ]),
                            ],
                            1
                          ),
                      _vm.canUseLoserPlayoff()
                        ? _c(
                            "div",
                            [
                              _c("app-form-label", [
                                _vm._v("Enable loser playoff? "),
                                _c("small", [
                                  _vm._v(
                                    "(A playoff matchup between the last place teams)"
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-layout",
                                {
                                  staticClass: "mb-8",
                                  attrs: { "align-center": "" },
                                },
                                [
                                  _c("v-simple-checkbox", {
                                    staticClass: "form-check-input",
                                    attrs: { ripple: false },
                                    model: {
                                      value: _vm.form.enableLoserPlayoff,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "enableLoserPlayoff",
                                          $$v
                                        )
                                      },
                                      expression: "form.enableLoserPlayoff",
                                    },
                                  }),
                                  _c("span", [_vm._v("Yes")]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.leagueStarted
                        ? _c(
                            "app-primary-button",
                            {
                              staticClass: "btn btn-default",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(" Generate schedule ")]
                          )
                        : _vm._e(),
                      _c("saved-indicator", { attrs: { saved: _vm.saved } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.hasSchedule
        ? _c(
            "v-row",
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function () {
                        return [
                          _c("thead", [
                            _c(
                              "tr",
                              [
                                _c("th", [_vm._v("Week")]),
                                _vm._l(_vm.matchupsPerWeek, function (x) {
                                  return _c("th", { key: x }, [
                                    _vm._v("Matchup " + _vm._s(x)),
                                  ])
                                }),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.schedule.weeks, function (week) {
                              return _c(
                                "tr",
                                { key: week.week_number },
                                [
                                  _c("th", [_vm._v(_vm._s(week.week_number))]),
                                  _vm._l(
                                    week.matchups,
                                    function (matchup, index) {
                                      return _c("td", { key: index }, [
                                        matchup.type == "regular"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(matchup.away.name) +
                                                  " vs " +
                                                  _vm._s(matchup.home.name)
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(matchup.type_display)
                                              ),
                                            ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2027755491
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c("v-row", [_c("start-draft", { attrs: { league: _vm.league } })], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }