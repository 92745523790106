var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Admin Tools")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("status")
                },
              },
            },
            [_vm._v("Status")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("users")
                },
              },
            },
            [_vm._v("Users")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("problems")
                },
              },
            },
            [_vm._v("Problems")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("leagues")
                },
              },
            },
            [_vm._v("Leagues")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("maintenance")
                },
              },
            },
            [_vm._v("Maintenance")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("sim")
                },
              },
            },
            [_vm._v("Simulation")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "caption",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.setView("reports")
                },
              },
            },
            [_vm._v("Reports")]
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.view == "status" ? _c("status") : _vm._e(),
          _vm.view == "users" ? _c("users") : _vm._e(),
          _vm.view == "problems" ? _c("problems") : _vm._e(),
          _vm.view == "leagues" ? _c("leagues") : _vm._e(),
          _vm.view == "maintenance"
            ? _c("div", [
                _vm._v(" TODO: Change year"),
                _c("br"),
                _vm._v(" TODO: End the year (stop jobs, change state)"),
                _c("br"),
                _vm._v(
                  " TODO: Start the year (start jobs, change state, fetch list of games)"
                ),
                _c("br"),
              ])
            : _vm._e(),
          _vm.view == "sim" ? _c("simulation") : _vm._e(),
          _vm.view == "reports" ? _c("reports") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }