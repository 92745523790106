var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [
        _vm._v(
          _vm._s(_vm.game.away.location) +
            " at " +
            _vm._s(_vm.game.home.location)
        ),
      ]),
      _c("p", { staticClass: "text-subtitle" }, [
        _vm._v(
          " If you need to make corrections to the game data, you can do so here. Please use official sources to verify data. "
        ),
      ]),
      _c(
        "p",
        [
          _vm.isAdmin
            ? _c("AppPrimaryButton", { on: { click: _vm.updateData } }, [
                _vm._v("Update source data"),
              ])
            : _vm._e(),
          _c(
            "AppDefaultButton",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Close Game")]
          ),
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab", [_vm._v(_vm._s(_vm.game.away.location))]),
          _c("v-tab", [_vm._v(_vm._s(_vm.game.home.location))]),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("ReviewTeam", {
                        attrs: {
                          season: _vm.game.year,
                          players: _vm.awayPlayers,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [
                      _c("ReviewTeam", {
                        attrs: {
                          season: _vm.game.year,
                          players: _vm.homePlayers,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }