<template></template>

<script>
import { firestore } from "../modules/firebase"

export default {
  name: "SystemBar",
  data() {
    return {}
  },

  computed: {},
}
</script>
