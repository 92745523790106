var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scoreboard
    ? _c(
        "div",
        [
          !_vm.reviewGame
            ? _c(
                "v-simple-table",
                [
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Date")]),
                        _c("th", [_vm._v("Game")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.scoreboard.games, function (game) {
                        return _c("tr", { key: game.game_id }, [
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.hoursAgo(game.date_start)) +
                                " hours ago"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(game.away.location) +
                                " at " +
                                _vm._s(game.home.location)
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "AppPrimaryButton",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.reviewGame = game
                                    },
                                  },
                                },
                                [_vm._v("Review")]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                ],
                2
              )
            : _c("ReviewGame", {
                attrs: { game: _vm.reviewGame },
                on: {
                  close: function ($event) {
                    _vm.reviewGame = null
                  },
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }