<template>
  <a href="https://github.com/mdryden/110yards/wiki#why-does-110-yards-use-sign-in-links" target="_blank"
    >Why not a password?</a
  >
</template>

<script>
export default {
  name: "WhyPasswordless",
}
</script>
