var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "socialLogins" } },
    [
      _c(
        "v-btn",
        { staticClass: "google mb-2", on: { click: _vm.signInWithGoogle } },
        [
          _c("v-icon", [_vm._v("mdi-google")]),
          _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
          _vm._v(" Continue with Google "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }