var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.switchesLoaded
    ? _c(
        "v-app",
        [
          _c("app-header", { attrs: { siteOffline: _vm.siteOffline } }),
          _c(
            "v-main",
            [
              _vm.showMessage
                ? _c(
                    "v-system-bar",
                    {
                      staticClass: "pa-2",
                      class: _vm.systemBarClass,
                      attrs: { height: "auto", color: _vm.siteMessage.color },
                      on: {
                        click: function ($event) {
                          return _vm.clickMessage()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.siteMessage.text) + " ")]
                  )
                : _vm._e(),
              !_vm.siteOffline
                ? _c(
                    "v-container",
                    {
                      staticClass: "spacing-playground pa-1",
                      attrs: { id: "body", fluid: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "container" },
                        [
                          _vm.donateAtTop ? _c("donate") : _vm._e(),
                          _c("router-view"),
                          _c("throbber"),
                          _vm.donateAtBotton
                            ? _c("donate", { attrs: { top: false } })
                            : _vm._e(),
                          _c("app-footer"),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("snack-bar", { ref: "snackBar" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }