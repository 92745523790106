var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "registration-control", attrs: { cols: "12" } },
        [
          _vm.league.registration_closed
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Registration for this league has been closed, so no other managers may join."
                    ),
                  ]),
                  !_vm.leagueStarted
                    ? _c(
                        "app-primary-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.reopenRegistration()
                            },
                          },
                        },
                        [_vm._v(" Re-open registration ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.league.is_full && !_vm.league.registration_closed
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      "Registration for this league is open. To prevent new registrations, generate a schedule or click here:"
                    ),
                  ]),
                  _c(
                    "app-primary-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.closeRegistration()
                        },
                      },
                    },
                    [_vm._v(" Close registration ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("start-draft", { attrs: { league: _vm.league } }),
        ],
        1
      ),
      !_vm.leagueStarted && !_vm.league.registration_closed
        ? _c(
            "v-col",
            { staticClass: "invite-options", attrs: { md: "6", cols: "12" } },
            [
              _vm.enableInviteByEmail
                ? _c("h4", [_vm._v("Invite manager by email:")])
                : _vm._e(),
              _vm.enableInviteByEmail
                ? _c("div", { staticClass: "form-inline" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            "solo-inverted": "",
                            dense: "",
                            rules: _vm.inviteEmailRules,
                            "append-outer-icon": "mdi-send",
                          },
                          on: {
                            "click:append-outer": function ($event) {
                              return _vm.sendInvite()
                            },
                          },
                          model: {
                            value: _vm.inviteEmail,
                            callback: function ($$v) {
                              _vm.inviteEmail = $$v
                            },
                            expression: "inviteEmail",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.enableInviteByEmail
                ? _c("h4", [_vm._v("Or send this link:")])
                : _c("h4", [_vm._v("Invite link:")]),
              _c("div", { staticClass: "form-inline" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        id: "join-link",
                        "solo-inverted": "",
                        dense: "",
                        readonly: "",
                        "append-outer-icon": "mdi-content-copy",
                      },
                      on: {
                        "click:append-outer": function ($event) {
                          return _vm.copyJoinLink()
                        },
                      },
                      model: {
                        value: _vm.joinLink,
                        callback: function ($$v) {
                          _vm.joinLink = $$v
                        },
                        expression: "joinLink",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { md: "6", cols: "12" } },
        [
          _c("h4", [_vm._v("League ID:")]),
          _c("v-text-field", {
            attrs: {
              id: "league-id",
              "solo-inverted": "",
              dense: "",
              readonly: "",
              "append-outer-icon": "mdi-content-copy",
            },
            on: {
              "click:append-outer": function ($event) {
                return _vm.copyLeagueId()
              },
            },
            model: {
              value: _vm.league.id,
              callback: function ($$v) {
                _vm.$set(_vm.league, "id", $$v)
              },
              expression: "league.id",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }