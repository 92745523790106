var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: { app: "", color: "header", dark: "", "extension-height": "30" },
      scopedSlots: _vm._u(
        [
          _vm.hasLeague && _vm.league
            ? {
                key: "extension",
                fn: function () {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-11 mt-n3 nav-secondary",
                        attrs: {
                          text: "",
                          small: "",
                          to: {
                            name: "league-settings",
                            params: { leagueId: _vm.league.id },
                          },
                        },
                      },
                      [_vm._v(" Scoring ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-n3 nav-secondary",
                        attrs: {
                          text: "",
                          small: "",
                          to: {
                            name: "league-schedule",
                            params: { leagueId: _vm.leagueId },
                          },
                        },
                      },
                      [_vm._v(" Schedule ")]
                    ),
                    _vm.isCommissioner || _vm.isAdmin
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mt-n3 nav-secondary",
                            attrs: {
                              small: "",
                              text: "",
                              to: {
                                name: "commissioner",
                                params: { leagueId: _vm.leagueId },
                              },
                            },
                          },
                          [_vm._v(" Commissioner ")]
                        )
                      : _vm._e(),
                    _vm.isAdmin
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mt-n3 nav-secondary",
                            attrs: {
                              small: "",
                              text: "",
                              to: {
                                name: "league-admin",
                                params: { leagueId: _vm.leagueId },
                              },
                            },
                          },
                          [_vm._v(" Waiver Results ")]
                        )
                      : _vm._e(),
                    _vm.hasNotes
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mt-n3 nav-secondary",
                            attrs: {
                              text: "",
                              small: "",
                              to: {
                                name: "league-notes",
                                params: { leagueId: _vm.leagueId },
                              },
                            },
                          },
                          [_vm._v(" Notes ")]
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("home-link"),
      _vm.hasLeague
        ? _c("league-link", {
            staticClass: "nav-primary",
            attrs: { leagueId: _vm.leagueId },
          })
        : _vm._e(),
      _vm.hasRoster
        ? _c("roster-link", {
            staticClass: "nav-primary",
            attrs: { leagueId: _vm.leagueId, userId: _vm.userId },
          })
        : _vm._e(),
      _vm.hasLeague
        ? _c("players-link", {
            staticClass: "nav-primary",
            attrs: { leagueId: _vm.leagueId },
          })
        : _vm._e(),
      _c("v-spacer"),
      !_vm.isAnonymous
        ? _c("profile-link", { staticClass: "nav-primary" })
        : _vm._e(),
      _c("support-link", { staticClass: "nav-primary" }),
      _c("faq-link", { staticClass: "nav-primary" }),
      _vm.isAdmin ? _c("admin-link", { staticClass: "nav-primary" }) : _vm._e(),
      _vm.isMod ? _c("mod-link", { staticClass: "nav-primary" }) : _vm._e(),
      _c("discord-link", { staticClass: "nav-primary" }),
      _vm.isAnonymous
        ? _c("log-in-link", { staticClass: "nav-primary" })
        : _vm._e(),
      !_vm.isAnonymous
        ? _c("log-out-link", { staticClass: "nav-primary" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }