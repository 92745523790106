var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "roster",
              params: { leagueId: _vm.leagueId, rosterId: _vm.roster.id },
            },
          },
        },
        [_vm._v(_vm._s(_vm.rosterName))]
      ),
      _vm.shouldTrim ? _c("span", [_vm._v("...")]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }