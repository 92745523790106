var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league
    ? _c(
        "v-card",
        [
          _c("v-card-subtitle", { staticClass: "subtitle mt-n10" }, [
            _vm._v(
              " You can use the notes to keep track of league information (eg: payouts, gentleman's rules). League notes are visible to all manager. "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c("app-text-area", {
                    attrs: { label: "Notes" },
                    model: {
                      value: _vm.form.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "notes", $$v)
                      },
                      expression: "form.notes",
                    },
                  }),
                  _c("app-primary-button", [_vm._v("Update")]),
                  _c("saved-indicator", { attrs: { saved: _vm.saved } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }