var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.reviewPlayer
    ? _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Approve new players")]),
          _c("v-card-subtitle", [
            _vm._v(
              " Players in the system are identified by name and date of birth. When new players are detected by the import system, there is a risk that it is because a player's name has changed, and they are not actually new to the system. To avoid issues with duplicate players, all new players must be approved manually. "
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-simple-table",
                [
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Name")]),
                        _c("th", [_vm._v("Team")]),
                        _c("th", [_vm._v("Position")]),
                        _c("th", [_vm._v("Actions")]),
                      ]),
                    ]),
                    _vm.playersToApprove && _vm.playersToApprove.length != 0
                      ? _c(
                          "tbody",
                          _vm._l(_vm.playersToApprove, function (player) {
                            return _c("tr", { key: player.id }, [
                              _c("td", [_vm._v(_vm._s(player.display_name))]),
                              _c("td", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "team-icon",
                                    class: player.team.abbr,
                                  },
                                  [_vm._v(_vm._s(player.team.abbr))]
                                ),
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(player.position.toUpperCase())),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "AppPrimaryButton",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.review(player)
                                        },
                                      },
                                    },
                                    [_vm._v("Review")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        )
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "4" } }, [
                              _vm._v("No players to approve"),
                            ]),
                          ]),
                        ]),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Review player")]),
          _c("v-card-subtitle", [
            _vm._v(
              " Please review the player below. If this player is already in the system, please select an existing player to match with. "
            ),
          ]),
          _c("v-card-text", [
            _vm._v(
              " " +
                _vm._s(_vm.reviewPlayer.display_name) +
                " - " +
                _vm._s(_vm.reviewPlayer.team.abbr.toUpperCase()) +
                " "
            ),
            _c("br"),
            _c("label", [_vm._v("Position: ")]),
            _vm._v(_vm._s(_vm.reviewPlayer.position.toUpperCase()) + " "),
          ]),
          _vm.matchPlayer
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    { attrs: { color: "orange", icon: "mdi-alert" } },
                    [
                      _c("strong", [_vm._v("Warning:")]),
                      _vm._v(
                        " Matching this player with an existing player will link the two players together. This will cause issues with player stats if the two players are not actually the same person. Please be sure that this is the same player before matching. "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "mb-5" }, [
                    _c("h3", [_vm._v("Original player")]),
                    _c("label", [_vm._v("Name:")]),
                    _vm._v(" " + _vm._s(_vm.matchPlayer.display_name) + " "),
                    _c("br"),
                    _c("label", [_vm._v("Date of birth: ")]),
                    _vm._v(
                      _vm._s(_vm.formatDate(_vm.matchPlayer.birth_date)) + " "
                    ),
                  ]),
                  _c("div", { staticClass: "mb-5" }, [
                    _c("h3", [_vm._v("Updated player")]),
                    _c("label", [_vm._v("Name:")]),
                    _vm._v(" " + _vm._s(_vm.reviewPlayer.display_name) + " "),
                    _c("br"),
                    _c("label", [_vm._v("Date of birth: ")]),
                    _vm._v(
                      _vm._s(_vm.formatDate(_vm.reviewPlayer.birth_date)) + " "
                    ),
                  ]),
                  _c(
                    "AppPrimaryButton",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.match()
                        },
                      },
                    },
                    [_vm._v("Confirm and match players")]
                  ),
                  _c(
                    "AppDefaultButton",
                    {
                      staticClass: "ml-2",
                      on: {
                        click: function ($event) {
                          _vm.matchPlayer = null
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _c(
                "v-card-text",
                [
                  _c(
                    "h3",
                    [
                      _vm._v("Search for matches: "),
                      _c("AppTextField", {
                        model: {
                          value: _vm.searchCriteria,
                          callback: function ($$v) {
                            _vm.searchCriteria = $$v
                          },
                          expression: "searchCriteria",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h5", [
                    _vm._v(
                      "Note: if a player's name has changed, he may appear as a free agent in the matches list."
                    ),
                  ]),
                  _c(
                    "v-simple-table",
                    [
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Name")]),
                            _c("th", [_vm._v("Team")]),
                            _c("th", [_vm._v("Position")]),
                            _c("th", [_vm._v("Actions")]),
                          ]),
                        ]),
                        _vm.anyMatches
                          ? _c(
                              "tbody",
                              _vm._l(_vm.matches, function (player) {
                                return _c("tr", { key: player.id }, [
                                  _c("td", [
                                    _vm._v(_vm._s(player.display_name)),
                                  ]),
                                  _c("td", [
                                    !player.is_free_agent
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "team-icon",
                                            class: player.team.abbr,
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                player.team.abbr.toUpperCase()
                                              )
                                            ),
                                          ]
                                        )
                                      : _c("span", [_vm._v("Free agent")]),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(player.position.toUpperCase())
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "AppPrimaryButton",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.matchPlayer = player
                                            },
                                          },
                                        },
                                        [_vm._v("Match")]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c("tbody", [
                              _c("tr", [
                                _vm.criteriaTooShort
                                  ? _c("td", { attrs: { colspan: "4" } }, [
                                      _vm._v(
                                        "Enter a search criteria to find matches"
                                      ),
                                    ])
                                  : _c("td", { attrs: { colspan: "4" } }, [
                                      _vm._v(
                                        'No matches for "' +
                                          _vm._s(_vm.searchCriteria) +
                                          '"'
                                      ),
                                    ]),
                              ]),
                            ]),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
          !_vm.matchPlayer
            ? _c(
                "v-card-actions",
                [
                  _c(
                    "AppPrimaryButton",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.addNew()
                        },
                      },
                    },
                    [_vm._v("Add new player")]
                  ),
                  _c(
                    "AppDefaultButton",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clear()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }