var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-btn", { attrs: { text: "", to: "/profile" } }, [
    _vm._v("Profile"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }