var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-textarea", {
    attrs: {
      outlined: "",
      filled: "",
      dense: "",
      label: _vm.calculatedLabel,
      rules: _vm.rules,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      required: _vm.required,
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }