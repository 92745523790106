var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-5" },
    [
      _c("router-link", { attrs: { to: { name: "faq", hash: "#waivers" } } }, [
        _vm._v("How do waivers work?"),
      ]),
      _c("h4", [_vm._v("Active bids")]),
      _c(
        "v-simple-table",
        [
          [
            _c("thead", [
              _c("tr", [
                _c("th"),
                _c("th", [_vm._v("Player")]),
                _c("th", [_vm._v("Bid")]),
                _c("th", [_vm._v("Drop Player")]),
              ]),
            ]),
            _c(
              "tbody",
              [
                !_vm.roster.waiver_bids || _vm.roster.waiver_bids.length == 0
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "4" } }, [
                        _vm._v("You haven't bid on any yet this week"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._l(_vm.roster.waiver_bids, function (bid, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "td",
                      [
                        _c(
                          "app-default-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.cancelBid(index)
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("player-link", {
                          attrs: { leagueId: _vm.leagueId, player: bid.player },
                        }),
                      ],
                      1
                    ),
                    _c("td", [_vm._v("$" + _vm._s(bid.amount))]),
                    _c(
                      "td",
                      [
                        bid.drop_player
                          ? _c("player-link", {
                              attrs: {
                                leagueId: _vm.leagueId,
                                player: bid.drop_player,
                              },
                            })
                          : _c("span", [_vm._v("N/A")]),
                      ],
                      1
                    ),
                  ])
                }),
              ],
              2
            ),
          ],
        ],
        2
      ),
      _c("h4", { staticClass: "mt-5" }, [_vm._v("Recent bids")]),
      _c(
        "v-simple-table",
        [
          [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Player")]),
                _c("th", [_vm._v("Bid")]),
                _c("th", [_vm._v("Drop Player")]),
                _c("th", [_vm._v("Result")]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.roster.processed_waiver_bids, function (bid, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "td",
                      [
                        _c("player-link", {
                          attrs: {
                            leagueId: _vm.leagueId,
                            player: bid.player,
                            showTeamLogo: false,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [_vm._v("$" + _vm._s(bid.amount))]),
                    _c(
                      "td",
                      [
                        bid.drop_player
                          ? _c("player-link", {
                              attrs: {
                                leagueId: _vm.leagueId,
                                player: bid.drop_player,
                                showTeamLogo: false,
                              },
                            })
                          : _c("span", [_vm._v("N/A")]),
                      ],
                      1
                    ),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.getBidResult(bid.result)) + " "),
                    ]),
                  ])
                }),
                !_vm.roster.processed_waiver_bids ||
                _vm.roster.processed_waiver_bids.length == 0
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "4" } }, [
                        _vm._v("No recently processed bids"),
                      ]),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }