var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.league
    ? _c(
        "v-container",
        { class: _vm.mobile ? "mobile" : "" },
        _vm._l(_vm.positions, function (position) {
          return _c(
            "v-row",
            { key: position.id, staticClass: "position-row" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12 px-0" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "trim pl-4", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0" },
                                [
                                  _c("player-link", {
                                    attrs: {
                                      player: _vm.getPlayer(
                                        position.id,
                                        _vm.away
                                      ),
                                      leagueId: _vm.league.id,
                                      shortenName: _vm.mobile,
                                      showTeamLogo: !_vm.mobile,
                                      showPosition: !_vm.mobile,
                                      showStatus: !_vm.mobile,
                                      showShortPlayerStatus: _vm.mobile,
                                      showNational: !_vm.mobile,
                                      maxNameLength: _vm.mobile ? 10 : null,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.mobile
                            ? _c(
                                "v-row",
                                {},
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "mt-n2 pb-0 grey--text caption",
                                    },
                                    [
                                      _vm.getPlayer(position.id, _vm.away)
                                        ? _c(
                                            "span",
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getPlayer(
                                                      position.id,
                                                      _vm.away
                                                    ).team.abbr
                                                  )
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " - " +
                                                    _vm._s(
                                                      _vm
                                                        .getPlayer(
                                                          position.id,
                                                          _vm.away
                                                        )
                                                        .position.toUpperCase()
                                                    )
                                                ),
                                              ]),
                                              _vm.getPlayer(
                                                position.id,
                                                _vm.away
                                              )
                                                ? _c("national-status", {
                                                    attrs: {
                                                      national_status:
                                                        _vm.getPlayer(
                                                          position.id,
                                                          _vm.away
                                                        ).national_status,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {},
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-n3 pb-0 grey--text caption",
                                },
                                [
                                  _vm.scoreboard
                                    ? _c("game-state", {
                                        attrs: {
                                          player: _vm.getPlayer(
                                            position.id,
                                            _vm.away
                                          ),
                                          short: _vm.mobile,
                                          scoreboard: _vm.scoreboard,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0 text-right" },
                                [
                                  _c("position-score", {
                                    attrs: {
                                      position: _vm.getPosition(
                                        position.id,
                                        _vm.away
                                      ),
                                      leagueId: _vm.league.id,
                                      weekNumber: _vm.weekNumber,
                                      scoreboard: _vm.scoreboard,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "caption grey--text" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-1 text-right" },
                                [
                                  _vm.enableProjections
                                    ? _c("player-projection", {
                                        attrs: {
                                          leagueId: _vm.league.id,
                                          player: _vm.getPlayer(
                                            position.id,
                                            _vm.away
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center grey--text px-0",
                          attrs: { cols: "2" },
                        },
                        [_vm._v(_vm._s(_vm.getPositionName(position)))]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0 text-left" },
                                [
                                  _c("position-score", {
                                    attrs: {
                                      position: _vm.getPosition(
                                        position.id,
                                        _vm.home
                                      ),
                                      leagueId: _vm.league.id,
                                      weekNumber: _vm.weekNumber,
                                      scoreboard: _vm.scoreboard,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "caption grey--text" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-1 text-left" },
                                [
                                  _vm.enableProjections
                                    ? _c("player-projection", {
                                        attrs: {
                                          leagueId: _vm.league.id,
                                          player: _vm.getPlayer(
                                            position.id,
                                            _vm.home
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-0 pr-4", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pb-0 text-right" },
                                [
                                  _c("player-link", {
                                    attrs: {
                                      player: _vm.getPlayer(
                                        position.id,
                                        _vm.home
                                      ),
                                      leagueId: _vm.league.id,
                                      shortenName: _vm.mobile,
                                      showTeamLogo: !_vm.mobile,
                                      showPosition: !_vm.mobile,
                                      showStatus: !_vm.mobile,
                                      showShortPlayerStatus: _vm.mobile,
                                      showNational: !_vm.mobile,
                                      maxNameLength: _vm.mobile ? 10 : null,
                                      reverse: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.mobile
                            ? _c(
                                "v-row",
                                {},
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "mt-n2 pb-0 grey--text caption text-right",
                                    },
                                    [
                                      _vm.getPlayer(position.id, _vm.home)
                                        ? _c(
                                            "span",
                                            [
                                              _vm.getPlayer(
                                                position.id,
                                                _vm.home
                                              )
                                                ? _c("national-status", {
                                                    attrs: {
                                                      national_status:
                                                        _vm.getPlayer(
                                                          position.id,
                                                          _vm.home
                                                        ).national_status,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getPlayer(
                                                      position.id,
                                                      _vm.home
                                                    ).team.abbr
                                                  )
                                                ),
                                              ]),
                                              _c("span", [
                                                _vm._v(
                                                  " - " +
                                                    _vm._s(
                                                      _vm
                                                        .getPlayer(
                                                          position.id,
                                                          _vm.home
                                                        )
                                                        .position.toUpperCase()
                                                    )
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            {},
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "mt-n3 pb-0 grey--text caption text-right",
                                },
                                [
                                  _vm.scoreboard
                                    ? _c("game-state", {
                                        attrs: {
                                          player: _vm.getPlayer(
                                            position.id,
                                            _vm.home
                                          ),
                                          short: _vm.mobile,
                                          scoreboard: _vm.scoreboard,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }