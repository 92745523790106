var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "lineup" },
    [
      _vm.showNewRosterUITip && !_vm.confirming
        ? _c(
            "v-alert",
            {
              staticClass: "caption",
              attrs: { outlined: "", dense: "", type: "info" },
            },
            [
              _vm._v(
                "Move and drop players by clicking the position button on the left"
              ),
            ]
          )
        : _vm._e(),
      _vm.confirming
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.confirmMessage))]),
              _c(
                "app-primary-button",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.cancelConfirmation()
                    },
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "app-default-button",
                { staticClass: "mr-2", on: { click: _vm.confirmAction } },
                [_vm._v("Yes, do it")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.positions && !_vm.confirming
        ? _vm._l(_vm.sortedSpots, function (spot) {
            return _c("lineup-spot", {
              key: spot.id,
              class: spot.position_type,
              attrs: {
                active: true,
                spot: spot,
                leagueId: _vm.leagueId,
                player: spot.player,
                canEdit: _vm.canEdit,
                playerToBeMoved: _vm.playerToBeMoved,
                scoreboard: _vm.scoreboard,
              },
              on: {
                confirmDrop: _vm.confirmDropPlayer,
                movePlayer: _vm.showMoveTargets,
                acceptPlayer: _vm.movePlayer,
                cancelMovePlayer: _vm.cancelMovePlayer,
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }