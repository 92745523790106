var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "nav-item nav-link",
      attrs: {
        text: "",
        to: {
          name: "roster",
          params: { leagueId: _vm.leagueId, rosterId: _vm.userId },
        },
      },
    },
    [_vm._v("My Team")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }