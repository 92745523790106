var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAdmin
    ? _c(
        "div",
        [
          _c(
            "v-simple-table",
            [
              [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c("th", [_vm._v("Email")]),
                    _c("th", [_vm._v("Last sign in")]),
                    _c("th", [_vm._v("Actions")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.users, function (user) {
                    return _c(
                      "tr",
                      { key: user.id },
                      [
                        !_vm.toDelete || _vm.toDelete == _vm.league
                          ? [
                              _c(
                                "td",
                                [
                                  user.is_admin
                                    ? _c(
                                        "v-chip",
                                        { attrs: { color: "red" } },
                                        [_vm._v("Admin")]
                                      )
                                    : _vm._e(),
                                  user.is_mod
                                    ? _c(
                                        "v-chip",
                                        { attrs: { color: "orange" } },
                                        [_vm._v("Mod")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("td", [_vm._v(_vm._s(user.email))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    user.last_sign_in
                                      ? _vm.formatDate(
                                          user.last_sign_in.toDate()
                                        )
                                      : "Never"
                                  )
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  !user.is_admin
                                    ? _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        { attrs: { icon: "" } },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _vm.isMod(user)
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.revokeMod(
                                                            user
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Revoke mod status"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.grantMod(
                                                            user
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          "Grant mod status"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }