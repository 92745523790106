import { render, staticRenderFns } from "./FaqLink.vue?vue&type=template&id=86cb9978&"
import script from "./FaqLink.vue?vue&type=script&lang=js&"
export * from "./FaqLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fantasy/fantasy/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('86cb9978')) {
      api.createRecord('86cb9978', component.options)
    } else {
      api.reload('86cb9978', component.options)
    }
    module.hot.accept("./FaqLink.vue?vue&type=template&id=86cb9978&", function () {
      api.rerender('86cb9978', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/nav/FaqLink.vue"
export default component.exports