import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=d9e31676&"
import script from "./Admin.vue?vue&type=script&lang=js&"
export * from "./Admin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fantasy/fantasy/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d9e31676')) {
      api.createRecord('d9e31676', component.options)
    } else {
      api.reload('d9e31676', component.options)
    }
    module.hot.accept("./Admin.vue?vue&type=template&id=d9e31676&", function () {
      api.rerender('d9e31676', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/Admin.vue"
export default component.exports