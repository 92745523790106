var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.league
        ? _c("league-header", {
            attrs: {
              leagueName: _vm.league.name,
              leagueId: _vm.league.id,
              season: _vm.season,
            },
          })
        : _vm._e(),
      _vm.seasonSummary
        ? _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "heading-6" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("v-card-text", [
                _c(
                  "p",
                  { staticClass: "font-weight-medium" },
                  [
                    _c("span", [_vm._v("Champion: ")]),
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { small: "", color: "grey" },
                      },
                      [_vm._v("mdi-trophy")]
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "leagueSeasonRoster",
                                params: {
                                  season: _vm.seasonSummary.id,
                                  leagueId: _vm.leagueId,
                                  rosterId:
                                    _vm.seasonSummary.champion.roster_id,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(this.seasonSummary.champion.name) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "font-weight" },
                  [
                    _c("span", [_vm._v("Runner-up: ")]),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "leagueSeasonRoster",
                            params: {
                              season: _vm.seasonSummary.id,
                              leagueId: _vm.leagueId,
                              rosterId: _vm.seasonSummary.runner_up.roster_id,
                            },
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(this.seasonSummary.runner_up.name) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("v-card-title", { staticClass: "subtitle-1" }, [
                _vm._v("Regular Season Standings"),
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.rosters, function (roster) {
                  return _c(
                    "v-row",
                    { key: roster.id, staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "roster-name",
                          attrs: { cols: "10", md: "6" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(roster.regular_season_rank) + ". "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "leagueSeasonRoster",
                                  params: {
                                    season: _vm.seasonSummary.id,
                                    leagueId: _vm.leagueId,
                                    rosterId: roster.roster_id,
                                  },
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(roster.name) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "2", md: "1" } },
                        [_vm._v(_vm._s(roster.record))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }