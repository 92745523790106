var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "7" } },
        [
          _vm.roster
            ? _c(
                "v-card",
                [
                  !_vm.editing
                    ? _c(
                        "v-card-text",
                        { staticClass: "font-weight-medium d-flex" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "8" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "pl-1 pb-0" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.roster.name) + " "
                                      ),
                                      _vm.canEditName && !_vm.editing
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-n1",
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                title: "Edit team name",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editing = true
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: _vm.editPencilColor,
                                                  },
                                                },
                                                [_vm._v("mdi-lead-pencil")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "caption pl-1 py-0" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.roster.record) +
                                          " | " +
                                          _vm._s(
                                            _vm.formatRank(_vm.roster.rank)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-col", {
                                    staticClass: "caption pl-1 py-0",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "3" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "pb-0 text-right font-weight-medium",
                                    },
                                    [
                                      _c("score", {
                                        attrs: { score: _vm.score },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _vm.projection !== null
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "py-0 text-right caption",
                                        },
                                        [
                                          _vm._v(" Proj. "),
                                          _c("score", {
                                            attrs: { score: _vm.projection },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editing
                    ? _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { text: _vm.page != "roster" },
                              on: {
                                click: function ($event) {
                                  _vm.page = "roster"
                                },
                              },
                            },
                            [_vm._v("Lineup")]
                          ),
                          _vm.isOwner
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { text: _vm.page != "waivers" },
                                  on: {
                                    click: function ($event) {
                                      _vm.page = "waivers"
                                    },
                                  },
                                },
                                [_vm._v("Waiver bids")]
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editing
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.submit()
                                    },
                                  },
                                },
                                [
                                  !_vm.roster.name_changes_banned
                                    ? _c("app-text-field", {
                                        attrs: {
                                          label: "Roster name",
                                          rules: _vm.rosterNameRules,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.form.rosterName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "rosterName",
                                              $$v
                                            )
                                          },
                                          expression: "form.rosterName",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.roster.name_changes_banned
                                    ? _c(
                                        "v-alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "The commissioner has disabled name changes for your team"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.roster.name_changes_banned
                                    ? _c(
                                        "app-primary-button",
                                        { staticClass: "mr-2" },
                                        [_vm._v("Save")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "app-default-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.editing = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.editing
                    ? _c(
                        "v-card-text",
                        [
                          _vm.roster && _vm.league && _vm.page == "roster"
                            ? _c("lineup", {
                                ref: "lineup",
                                attrs: {
                                  roster: _vm.roster,
                                  league: _vm.league,
                                  canEdit: _vm.canEditRoster,
                                },
                              })
                            : _vm._e(),
                          _vm.isOwner && _vm.page == "waivers"
                            ? _c("waivers", {
                                attrs: {
                                  roster: _vm.roster,
                                  leagueId: _vm.leagueId,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-card-subtitle",
                    [
                      _vm.isCommissioner && !_vm.commissionerOverride
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.commissionerOverride = true
                                },
                              },
                            },
                            [_vm._v("Enter Commissioner Mode")]
                          )
                        : _vm._e(),
                      _vm.isCommissioner && _vm.commissionerOverride
                        ? _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.commissionerOverride = false
                                },
                              },
                            },
                            [_vm._v("Exit Commissioner Mode")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }