import { render, staticRenderFns } from "./SeasonRoster.vue?vue&type=template&id=51967224&"
import script from "./SeasonRoster.vue?vue&type=script&lang=js&"
export * from "./SeasonRoster.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/fantasy/fantasy/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51967224')) {
      api.createRecord('51967224', component.options)
    } else {
      api.reload('51967224', component.options)
    }
    module.hot.accept("./SeasonRoster.vue?vue&type=template&id=51967224&", function () {
      api.rerender('51967224', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/league/SeasonRoster.vue"
export default component.exports