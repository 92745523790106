var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { "max-width": "600px" } },
    [
      _c("v-card-title", [_vm._v("Create a fantasy league")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c("app-text-field", {
                attrs: {
                  label: "League Name",
                  rules: _vm.leagueNameRules,
                  required: "",
                },
                model: {
                  value: _vm.leagueName,
                  callback: function ($$v) {
                    _vm.leagueName = $$v
                  },
                  expression: "leagueName",
                },
              }),
              _c("label", { staticClass: "control-label" }, [
                _vm._v("Make this league private?"),
              ]),
              _c(
                "v-layout",
                { attrs: { "align-center": "" } },
                [
                  _c("v-simple-checkbox", {
                    staticClass: "form-check-input",
                    attrs: { ripple: false },
                    model: {
                      value: _vm.isPrivate,
                      callback: function ($$v) {
                        _vm.isPrivate = $$v
                      },
                      expression: "isPrivate",
                    },
                  }),
                  _c("span", [_vm._v("Yes")]),
                  _c("span", { staticClass: "text-caption ml-1" }, [
                    _vm._v(
                      "(Private leagues are hidden from the listing, and require a password to join)"
                    ),
                  ]),
                ],
                1
              ),
              _c("app-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPrivate,
                    expression: "isPrivate",
                  },
                ],
                attrs: { label: "League Password" },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _c("app-primary-button", [_vm._v("Create")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }